<template>
  <div style="padding-left: 30px;padding-right: 30px">
    <el-form :disabled="queryIssue" ref="form" :model="formData" label-width="180px" :rules="rules" size="medium">
      <el-table
          :data="singerSources"
          style="width: 100%;margin-bottom: 15px">
        <el-table-column
            prop="date"
            label="艺人">
          <template v-slot="{row}">
            {{ row.singerName }}
          </template>
        </el-table-column>
        <el-table-column
            prop="page_q"
            label="* 歌手Q音主页">
          <template v-slot="{row}">
            <el-input v-model="row.page_q" placeholder="" :maxlength="400"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="page_wy"
            label="歌手网易云主页">
          <template v-slot="{row}">
            <el-input v-model="row.page_wy" placeholder="" :maxlength="400"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="page_dy"
            label="汽水音乐主页">
          <template v-slot="{row}">
            <el-input v-model="row.page_qs" placeholder="" :maxlength="400"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="page_dy"
            label="歌手抖音主页">
          <template v-slot="{row}">
            <el-input v-model="row.page_dy" placeholder="" :maxlength="400"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <el-form-item label="是否申请资源位" required prop="need_fans" label-width="120px">
        <el-radio-group v-model="formData.need_fans">
          <el-radio :label="'1'">是</el-radio>
          <el-radio :label="'0'">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-table
          v-if="formData.need_fans ==='1'"
          :data="singerSources"
          style="width: 100%">
        <el-table-column
            prop="date"
            label="艺人">
          <template v-slot="{row}">
            {{ row.singerName }}
          </template>
        </el-table-column>
        <el-table-column
            prop="fans_q"
            label="* Q音粉丝量">
          <template v-slot="{row}">
            <el-input v-model="row.fans_q" placeholder="">
              <template slot="suffix">
                <div style="height: 100%;line-height: 3;">万</div>
              </template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="fans_kg"
            label="* 酷狗粉丝量">
          <template v-slot="{row}">
            <el-input v-model="row.fans_kg" placeholder="">
              <template slot="suffix">
                <div style="height: 100%;line-height: 3">万</div>
              </template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="fans_dy"
            label="* 抖音粉丝量">
          <template v-slot="{row}">
            <el-input v-model="row.fans_dy" placeholder="">
              <template slot="suffix">
                <div style="height: 100%;line-height: 3">万</div>
              </template>
            </el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-form>


  </div>
</template>

<script>
export default {
  name: "ResourceInfoForm",
  computed:{
  },
  props: {
    queryIssue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    singerSources: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    this.formData = this.value
  },

  data() {
    return {
      formData: {
        need_fans: '0'
      },
      rules: {
        need_fans: [{
          required: true,
          message: '请选择是否申请资源位',
          trigger: 'change'
        }]
      }
    }
  },

  watch: {
    value(val) {
      this.formData = val
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .cell{
    font-weight: 300;
  }
  .el-table__cell, .el-table tr, .el-table {
    background-color: transparent !important;
  }
}
</style>
