<template>
  <div style="padding-left: 60px;padding-top: 30px;padding-right: 60px">
    <el-form ref="form" :disabled="queryIssue" :model="formData" :rules="rules" label-width="110px" label-position="left" size="medium">
      <el-row :gutter="70">
       <el-col :span="12" v-for="tag in tagsFields" :key="tag.key">
         <el-form-item :label="tag.name" required>
           <el-select
               style="width: 100%;"
               clearable
               v-if="tag.type === 'checkbox' || tag.type === 'radio'"
               :multiple="tag.type === 'checkbox'"
               v-model="formData[tag.key]"
               :placeholder="'请选择' + tag.name">
             <el-option
                 v-for="item in tag.value"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id">
             </el-option>
           </el-select>

           <el-input-number class="el-input-number-speed" :min="0" :step="1" :precision="0" :step-strictly="true"  style="width: 100%;" :controls="false"  v-else-if="tag.type === 'input'" v-model.number="formData[tag.key]" :placeholder="'请输入' + tag.name"></el-input-number>
         </el-form-item>
       </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "StyleInfoForm",

  props: {
    queryIssue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.formData = this.value
  },

  data () {
    return {
      formData: {}
    }
  },

  computed: {
    tagsFields() {
      return this.$store.state.other ? this.$store.state.other.tags : []
    },
    rules() {
      const rules = {}
      this.tagsFields.map(e => {
        rules[e.key] = [{
          required: true,
          message: '请选择' + e.name,
          trigger: e.type === 'checkbox' || e.type === 'radio' ? 'change' : 'blur'
        }]
      })
      return rules
    }
  },

  watch: {
    value(val) {
      this.formData = val
    }
  },
}
</script>

<style scoped lang="scss">
  ::v-deep.el-input-number-speed{
    .el-input{
      .el-input__inner{
        text-align: left;
      }
    }
  }
</style>
