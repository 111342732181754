<template>
  <div>
    <div style="padding-left: 60px;padding-top: 30px">
      <el-row :gutter="15">
        <el-form ref="elForm" :disabled="!routerQuery" :model="formData" :rules="rules" size="medium" label-width="140px" label-position="left">
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="歌曲名" prop="song_name">
                  <el-input v-model="formData.song_name" placeholder="请输入歌曲名" clearable
                            :style="{width: '100%'}"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label-width="0" prop="field114">
                  <el-button v-if="routerQuery" type="primary" size="medium" @click="asyncAlbumInfo" class="news-album"> 同步专辑信息 </el-button>
                  <span style="font-size: 12px;color: #999999;margin-left: 9px">同步歌曲名，所属艺人和发行时间</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="版权公司" prop="subject_no">
                  <div class="content-item">
                    <el-select v-model="formData.subject_no">
                      <el-option v-for="(item, index) in companyList"  :value="item.value" :key="index" :label="item.name"></el-option>
                    </el-select>
                    <el-tooltip class="item" effect="dark" content="歌曲入库所属公司，不提交发行" placement="bottom">
                      <el-image style="width: 14px;height: 14px;margin-left: 16px;" :src="require('../../../../assets/doubt.png')"></el-image>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="副标题" prop="song_title">
                  <el-input v-model="formData.song_title" placeholder="请输入副标题" clearable
                            :style="{width: '100%'}"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-form-item label="所属艺人" prop="singer_sources">
                  <el-select
                      v-model="formData.singer_sources"
                      placeholder="请输入艺人全称搜索"
                      clearable
                      filterable
                      multiple
                      remote
                      :remote-method="getSinger"
                      :loading="loading"
                      value-key="tmeSingerId"
                      :style="{width: '100%'}">
                    <el-option
                        style="height: 60px;display: flex;align-items: center"
                        v-for="(item, index) in singer_idOptionsComputed"
                        :key="item.tmeSingerId + index"
                        :label="item.singerName"
                        :value="item">
                      <el-image :src="item.singerPic" style="width: 35px;height: 35px;border-radius: 4px;margin-right: 15px">
                        <div slot="error" class="image-slot"></div>
                      </el-image>
                      <span>{{ item.singerName }}</span>
                    </el-option>
                    <div v-show="singerHasMore" style="text-align: center;cursor: pointer;font-size: 14px;color: #999999;line-height: 40px" @click="getSinger()">加载更多</div>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label-width="0" prop="field115">
                  <el-button v-if="routerQuery" type="primary" class="news-album" icon="el-icon-plus" size="medium" @click="$refs.artistPopup.initializeFn('新增艺人',0)" > 新增艺人 </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="14">
            <el-form-item label="版本类型" prop="version">
              <el-radio-group v-model="formData.version" size="medium">
                <el-radio v-for="(item, index) in versionOptions" :key="index" :label="item.value"
                          :disabled="item.disabled">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="版本名称" prop="song_version">
              <el-select v-model="formData.song_version" placeholder="请选择版本名称" clearable
                         filterable @change="formData.accom_version = formData.song_version + '伴奏'"
                         allow-create
                         :style="{width: '100%'}">
                <el-option v-for="(item, index) in song_versionOptions" :key="index" :label="item.label"
                           :value="item.value" :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="语言" prop="song_language">
              <el-select v-model="formData.song_language" placeholder="请选择语言" clearable
                         :style="{width: '100%'}">
                <el-option v-for="(item, index) in song_languageOptions" :key="index" :label="item.label"
                           :value="item.value" :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="发行时间" prop="song_issue">
              <el-date-picker type="datetime" v-model="formData.song_issue" format="yyyy-MM-dd HH:mm:ss"
                              value-format="yyyy-MM-dd HH:mm:ss" :style="{width: '100%'}" placeholder="请选择发行时间" clearable>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="发行伴奏" prop="is_accom">
              <el-radio-group v-model="formData.is_accom" size="medium">
                <el-radio v-for="(item, index) in is_accomOptions" :key="index" :label="item.value"
                          :disabled="item.disabled">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14" v-if="formData.is_accom === 1" v-show="false">
            <el-form-item label="伴奏是否带歌词" prop="accom_lyric_sync" required>
              <el-radio-group v-model="formData.accom_lyric_sync" size="medium">
                <el-radio v-for="(item, index) in is_accomOptions" :key="index" :label="item.value"
                          :disabled="item.disabled">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="14" v-if="formData.is_accom === 1">
            <el-form-item label="伴奏版本名称" prop="is_accom">
              <el-select v-model="formData.accom_version" placeholder="请选择版本名称" clearable
                         filterable
                         allow-create
                         :style="{width: '100%'}">
                <el-option v-for="(item, index) in song_versionOptions" :key="index" :label="item.label"
                           :value="item.value" :disabled="item.disabled"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <artist-popup @determine="determine" ref="artistPopup"></artist-popup>
  </div>
</template>

<script>
import artistPopup from "@/views/administration/components/artistPopup";
import baseInfo from './baseInfo'
import {getSinger} from "@/api"

export default {
  name: "BaseInfoForm",

  props: {
    routerQuery: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: baseInfo
    }
  },
  components:{
    artistPopup
  },
  mounted() {
    this.formData = this.value
    this.subCompanyFn()
  },

  data() {
    return {
      formData: baseInfo,

      companyList:[],

      rules: {
        song_name: [{
          required: true,
          message: '请输入歌曲名',
          trigger: 'blur'
        }],
        singer_sources: [{
          required: true,
          message: '请输入艺人全称搜索',
          trigger: 'blur'
        }],
        song_title: [],
        singer_id: [{
          required: true,
          message: '请输入艺人全称搜索',
          trigger: 'change'
        }],
        version: [],
        song_version: [],
        song_language: [{
          required: true,
          message: '请选择语言',
          trigger: 'change'
        }],
        song_issue: [{
          required: true,
          message: '请选择发行时间',
          trigger: 'change'
        }],
        is_accom: [{
          required: true,
          message: '是否伴奏不能为空',
          trigger: 'change'
        }],
      },
      loading: false,
      singer_idOptions: [],
      versionOptions: [{
        "label": "原版",
        "value": 1
      }, {
        "label": "翻唱",
        "value": 2
      }, {
        "label": "衍生版",
        "value": 3
      }],
      is_accomOptions: [{
        "label": "是",
        "value": 1
      }, {
        "label": "否",
        "value": 0
      }],

      singerQuery: '',
      singerPage: 1,
      singerHasMore: true
    }
  },

  methods: {
    subCompanyFn(){
      this.$post('/api/issue/universal/album/subCompany').then(r => {
        if(r.code === 0){
          this.companyList = r.data;
          if(this.companyList.find(e => e.value === 'WHBL') && !this.value.subject_no) {
            this.value.subject_no = 'WHBL'
          }
        }
      })
    },
    asyncAlbumInfo(){
      this.$emit('asyncAlbumInfo')
      this.$refs['elForm'].clearValidate()
    },
    getSinger(query = undefined) {
      if (query === undefined) {
        this.singerPage++
      } else {
        this.singerQuery = query
        this.singer_idOptions = []
        this.singerPage = 1
        this.singerHasMore = true
      }

      getSinger({singerName: this.singerQuery, page: this.singerPage, size: 20}).then(r => {
        if (r.data.data.length < 20) {
          this.singerHasMore = false
        }

        this.singer_idOptions = [
          ...this.singer_idOptions,
          ...r.data.data.map(e => {
            return {
              "singerName": e.singerName,
              "tmeSingerId": e.tmeSingerId.toString(),
              "singerPic": e.singerPic,
              page_q:e.qq_music_homepage,
              page_dy:e.douyin_homepage,
              page_wy:e.wangyi_homepage,
              fans_q:e.qq_music_fans,
              fans_kg:e.kugou_fans,
              fans_dy:e.douyin_fans,
              page_qs:e.qishui_homepage
            }
          })
        ]
        this.loading = false
      })
    },
    determine(val){
      this.$post('/api/issue/universal/singer/show',{id:val}).then(r=> {
        if(r.code === 0){
          this.$message.success('新增艺人成功')
        }
      })
    },
  },

  watch: {
    value(val) {
      this.formData = val
    }
  },
  computed: {
    singer_idOptionsComputed(){
      return [
          ...this.formData.singer_sources,
          ...this.singer_idOptions
      ]
    },
    song_versionOptions() {
      const result = []

      if (this.$store.state.song) {
        for (const e in this.$store.state.song.song_ver) {
          result.push({
            label: e, value: e
          })
        }
      }

      return result
    },

    song_languageOptions() {
      const result = []
      if (this.$store.state.album) {
        const keys = Object.keys(this.$store.state.album.language)

        for(let i = 0; i < keys.length; i++) {
          result.push({
            label: this.$store.state.album.language[keys[i]], value: keys[i]
          })
        }
      }

      return result
    },
  }
}
</script>

<style scoped lang="scss">

.news-album {
    width: 114px;
    height: 36px;
    padding: 0;
    border: none;
    background: rgba(255,0,77,.1);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ff004d;
}

</style>
