export default {
  apply_id: 0,
  song_name: "",
  song_title: "",
  singer_sources: [],
  version: 1,
  song_version: '',
  song_language: '0',
  song_issue: '',
  is_accom: 1,
  accom_lyric_sync: 0,
  accom_version: '伴奏',
  subject_no: ''
}
