<template>
  <div style="padding-left: 60px;padding-top: 20px;padding-right: 60px">
    <el-row :gutter="15">
      <el-col>
        <h5>扩展信息</h5>
      </el-col>
      <el-form :disabled="queryIssue" ref="elForm" :model="formData" :rules="rules" size="medium" label-position="left" label-width="100px" >
        <el-col :span="14">
          <el-form-item label="作词人" prop="lyricist" style="margin-bottom: 24px">
            <hi-select :disabled="queryIssue" v-model="formData.lyricist" placeholder="请输入作词人"></hi-select>
            <div class="info">多个作词人可输入回车分隔</div>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="作曲人" prop="composer" style="margin-bottom: 24px">
            <hi-select :disabled="queryIssue" v-model="formData.composer" placeholder="请输入作曲人"></hi-select>
            <div class="info">多个作曲人可输入回车分隔</div>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="编曲人" prop="arranger" style="margin-bottom: 24px">
            <hi-select :disabled="queryIssue" v-model="formData.arranger" placeholder="请输入编曲人"></hi-select>
            <div class="info">多个编曲人可输入回车分隔</div>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="制作人" prop="producer" style="margin-bottom: 24px">
            <hi-select :disabled="queryIssue" v-model="formData.producer" placeholder="请输入制作人"></hi-select>
            <div class="info">多个制作人可输入回车分隔</div>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="策划/工作室" prop="planer">
            <hi-select :disabled="queryIssue" v-model="formData.planer" placeholder="请输入策划/工作室"></hi-select>
            <div class="info" style="margin-left: -13px">多个策划/工作室可输入回车分隔</div>
          </el-form-item>
        </el-col>
      </el-form>

      <el-col>
        <h5>物料信息</h5>
      </el-col>
      <el-form :disabled="queryIssue"  ref="elForm" :model="formData" :rules="rules" size="medium" label-width="180px" label-position="top">
        <el-col :span="24">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item  prop="id_file_11" required>
                <span  slot="label">
                  原曲音频(wav)<el-tooltip style="vertical-align: middle" class="item" effect="dark" content="该音频文件用于TME发行" placement="top">
               <el-image style="width: 14px;height: 14px;margin-left: 16px;" :src="require('@/assets/doubt.png')"></el-image>
               </el-tooltip>
                </span>
                <hi-cos-upload :md5="formData.id_file_11_md5" :baseInfo="baseInfo" v-model="formData.id_file_11" file-type="other" accept=".wav" :kind="11"></hi-cos-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="id_file_14">
                <span slot="label">
                  封面源文件（psd）
                  <span style="color: #ff004d;font-size: 12px">(如有，请尽量上传)</span>
                </span>
                <hi-cos-upload :md5="formData.id_file_14_md5" :baseInfo="baseInfo"  v-model="formData.id_file_14" file-type="other" accept=".psd" :kind="14"></hi-cos-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item prop="id_file_12">
                 <span  slot="label">
                  伴奏-和声（wav）<el-tooltip style="vertical-align: middle" class="item" effect="dark" content="该音频文件用于TME发行" placement="top">
               <el-image style="width: 14px;height: 14px;margin-left: 16px;" :src="require('@/assets/doubt.png')"></el-image>
               </el-tooltip>
                </span>
                <hi-cos-upload :md5="formData.id_file_12_md5" :baseInfo="baseInfo"  v-model="formData.id_file_12" file-type="other" accept=".wav" :kind="12"></hi-cos-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="id_file_10">
                 <span slot="label">伴奏-无和声（wav）</span>
                <hi-cos-upload :md5="formData.id_file_10_md5" :baseInfo="baseInfo"  v-model="formData.id_file_10" file-type="other" accept=".wav" :kind="10"></hi-cos-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-form-item v-if="!!formData.id_file_15" label="音频分轨" prop="id_file_15">
            <el-input readonly v-model="formData.id_file_15" placeholder="请输入分轨所在的网盘地址"></el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <h5>物料信息</h5>
          <div style="background-color: #f5f5f5;color: #999999;padding: 9px 12px;font-size: 10px;margin-bottom: 20px">
            文件说明：<br>
            1、歌词文件的头部和正文中间需有：【未经著作权人许可 不得翻唱 翻录或使用】或【本音乐作品已获得正版授权】。  <br>
            2、歌词头部填歌名、作词、作曲、发行等信息。<br>
            3、歌词正文不要带时间戳(例如:[00:30.54])，只需要填纯文本。<br>
          </div>
        </el-col>
        <el-col :span="12">
          <el-form-item label="歌词文件（txt）" prop="id_file_3">
            <hi-cos-upload :base-info="baseInfo" v-model="formData.id_file_3" file-type="other" accept=".txt" :kind="3"></hi-cos-upload>
          </el-form-item>
        </el-col>
        <el-col></el-col>
        <el-col :span="12">
          <el-form-item label="歌词前缀" prop="lyric_head">
            <el-input  v-model="formData.lyric_head" type="textarea" placeholder="请输入歌词前缀" readonly :key="key"
                      :autosize="{minRows: 11, maxRows: 11}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="歌词正文" prop="lyric">
            <el-input v-model="formData.lyric" type="textarea" placeholder="请输入歌词正文" readonly :key="key"
                      :autosize="{minRows: 11, maxRows: 11}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import materialInfo from "@/views/issue/components/materialInfoForm/materialInfo"
import HiCosUpload from "@/components/HiCosUpload"
import {lyricParse} from "@/api"
import HiSelect from "@/components/HiSelect"

export default {
  name: 'MaterialInfoForm',

  components: {
    HiCosUpload,
    HiSelect
  },

  props: {
    queryIssue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    baseInfo:{
      type:Object,
      default:() => {},
    }
  },

  mounted() {
    this.formData = this.value
  },
  data() {
    return {
      formData: materialInfo,
      key: 0,
      rules: {
        lyricist: [],
        composer: [{
          required: true,
          message: '请输入作曲人',
          trigger: 'blur'
        }],
        arranger: [{
          required: true,
          message: '请输入 编曲人',
          trigger: 'blur'
        }],
        producer: [],
        planer: [{
          required: true,
          message: '请输入策划/工作室',
          trigger: 'blur'
        }]
      },
    }
  },

  computed: {
    singerName(){
      const singerName = this.baseInfo.singer_sources ? this.baseInfo.singer_sources.map(e => e.singerName) : []
      if (singerName.length > 0)
        return singerName.join(',')
      return undefined
    },
    file7Name() {
      if (!this.baseInfo.song_name) return

      let name = this.baseInfo.song_name + (this.baseInfo.song_version ? ' (' + this.baseInfo.song_version + ')' : '') + ' (剪辑版)'
      if (this.singerName) {
        name += '-' + this.singerName
      }
      return name
    }
  },

  watch: {
    value(val) {
      this.formData = val
    },
    'formData.id_file_3'(val){
      if (val === 0) return
      lyricParse({id: val}).then(r => {
        if (r.data) {
          this.formData.lyric_head = r.data.lyric_head
          this.formData.lyric = r.data.lyric_text
        } else {
          this.$alert(r.msg || '歌词文件上传失败，请按提示重新上传', '歌词文件上传失败', {
            confirmButtonText: '关闭'
          });
          this.formData.id_file_3 = 0
          this.formData.lyric_head = ''
          this.formData.lyric = ''
        }

        this.key++
      })
    }
  },

  created() {},

  methods: {
  }
}

</script>
<style>
.none {
  display: none;
}
</style>
<style scoped>
.el-upload__tip {
  line-height: 1.2;
}
::v-deep .el-input__suffix{
  display: none;
}
::v-deep .el-form-item__label span{
  font-family: PingFang SC-Light, system-ui !important;
}
.info{
  color: #ccc;
  font-size: 22px;
  line-height: 17px;
  position: absolute;
  transform: scale(.5);
  font-weight: 300;
  left: -65px;
}
</style>
