export default {
  lyricist: [],
  composer: [],
  arranger: [],
  producer: [],
  planer: [],
  id_file_11: 0,
  id_file_14: 0,
  id_file_12: 0,
  id_file_10: 0,
  id_file_11_md5: '',
  id_file_14_md5: '',
  id_file_12_md5: '',
  id_file_10_md5: '',
  id_file_7: '',
  id_file_16: 0,
  id_file_8: 0,
  id_file_15: undefined,
  id_file_3: 0,
  lyric_head: undefined,
  lyric: undefined,
}
