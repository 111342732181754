<template>
  <div class="upload-album">
    <div class="title" style="margin-bottom: 20px;">
      <div class="title-album">上传至专辑</div>
      <div class="news-album" v-if="!newlyBuildShow" @click="flag(true,false)"><i class="el-icon-plus"></i> 新建专辑</div>
    </div>
      <div class="choice"  v-if="!newlyBuildShow">
        <div class="left-icon" @click="choiceShow = !choiceShow">
          <el-image :src="require('@/assets/search-issue.png')"></el-image>
        </div>
        <div class="title" @click="pullDown">
          <el-input style="width: 250px;"  @input="searchFn" v-model="name"  placeholder="请选择专辑"></el-input>
        </div>
        <div class="right-icon" @click="choiceShow = !choiceShow" ><el-image style="transition: all .3s" :style="{'transform':choiceShow ? 'rotateZ(180deg)':'rotateZ(0deg)' }" :src="require('@/assets/down-issue.png')"></el-image></div>
      </div>
    <div class="choice-album" v-infinite-scroll="getScroll" :class="{'choice-album-active':choiceShow}">
     <div class="item" @click="flag(true,false)">
       <div class="newly">
          <div><i class="el-icon-plus"></i></div>
          <div>新建专辑</div>
       </div>
     </div>
     <div v-show="albumList.length > 0" class="item item-album" v-for="(item,index) in albumList" :key="index" @click="$parent.getAlbumDetails(item.value, true);flag(true,false);">
          <div style="width:90px; height: 90px;">
            <el-image style="width:90px; height: 90px;" :src="item.cover ? item.cover.location : ''">
              <el-image :src="require('@/assets/release/uploadDefault.png')" slot="error" style="width:90px; height: 90px;">
              </el-image>
              </el-image>
          </div>
         <div class="title" :title="item.name">{{item.name}}</div>
         <div class="subtitle" :title="item.singer">{{item.singer}}</div>
     </div>
      <div v-show="albumList.length === 0" style="width: 100px;margin: auto;text-align: center;font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;color: #999999;">
        暂无专辑
      </div>
    </div>
     <div class="modify" v-show="newlyBuildShow">
       <div class="nav">
         <div>{{ value.album_name ? `专辑《${value.album_name}》` : '新建专辑' }}</div>
         <div @click="isAway = !isAway" style="display: flex;align-items: center;cursor: pointer;">
           <div>详情</div>
           <el-image style="width: 24px;height: 24px; transition: all .3s;" :class="{rotateClass:isAway}" :src="require('../../../assets/up-issue.png')"></el-image>
         </div>
         <el-image v-if="routerQuery && [0,-1,-2].indexOf($parent.status) > -1 && $parent.verNum === 1" @click="handleDelete" style="width: 10px;height: 10px;cursor: pointer" :src="require('../../../assets/close.png')"></el-image>
       </div>
       <div class="content" :class="{'content-active':isAway}">
         <el-form :disabled="!routerQuery || !!$parent.songProtect" label-width="100px" :model="value" ref="form" :rules="rules" size="medium">
           <el-form-item label="版权公司" prop="subject_no">
             <div class="content-item">
               <el-select v-model="value.subject_no">
                 <el-option v-for="(item, index) in companyList"  :value="item.value" :key="index" :label="item.name"></el-option>
               </el-select>
               <el-tooltip class="item" effect="dark" content="歌曲入库所属公司，不提交发行" placement="bottom">
               <el-image style="width: 14px;height: 14px;margin-left: 16px;" :src="require('../../../assets/doubt.png')"></el-image>
               </el-tooltip>
             </div>
           </el-form-item>
           <el-form-item label="专辑名称" prop="album_name">
             <div class="content-item">
               <el-input placeholder="请输入专辑名称" v-model.trim="value.album_name" @blur="handleAlbumBlur"></el-input>
             </div>
           </el-form-item>
           <el-form-item label="专辑封面" prop="cover_map_id">
             <div class="content-item" style="display: flex;align-items: flex-start;">
               <el-image class="uploadImg" :src="imgUrl">
                 <el-image class="uploadImg" slot="error" :src="require('../../../assets/release/uploadDefault.png')"></el-image>
               </el-image>
               <div class="uploadBut">
                 <div class="uploadBut-header">
                   图片需为jpg、jpeg、png格式 <br>
                   正方形，尺寸为1:1
                 </div>
                 <hiCosUpload  accept=".jpg,.jpeg,.png" v-if="routerQuery" :drag="false" v-model="value.cover_map_id" :md5="value.cover_md5" file-type="other" :kind="4" cover :cover-name="coverName" :size="[2000, 3000]">
                   <el-button size="small" class="active" type="primary">{{value.cover_map_id ? '重新上传' : '上传封面'}}</el-button>
                 </hiCosUpload>
               </div>
             </div>
           </el-form-item>
           <el-form-item label="所属艺人" prop="singer_info">
             <div class="content-item">
               <el-select
                   v-model="value.singer_info"
                   placeholder="请输入艺人全称搜索"
                   clearable
                   filterable
                   multiple
                   remote
                   :remote-method="getSinger"
                   :loading="loading"
                   value-key="singer_id"
                   :style="{width: '450px'}">
                 <el-option
                     style="height: 60px;display: flex;align-items: center"
                     v-for="(item) in singer_infoOptions"
                     :key="item.singer_id"
                     :label="item.singer_name"
                     :value="item">
                   <el-image :src="item.singer_pic" style="width: 35px;height: 35px;border-radius: 4px;margin-right: 15px">
                     <div slot="error" class="image-slot"></div>
                   </el-image>
                   <span>{{ item.singer_name }}</span>
                 </el-option>
                 <div v-show="singerHasMore" style="text-align: center;cursor: pointer;font-size: 14px;color: #999999;line-height: 40px" @click="getSinger()">加载更多</div>
               </el-select>
               <el-button v-if="routerQuery" class="add-singer-but" @click="$refs.artistPopup.initializeFn('新增艺人',0)"><el-image style="width: 16px;height: 16px;vertical-align: middle;" :src="require('@/assets/add-artist.png')"></el-image><span style="vertical-align: middle;">新增艺人</span></el-button>
             </div>
           </el-form-item>
           <el-form-item label="专辑类型" prop="album_type">
             <div class="content-item">
               <el-select v-model="value.album_type">
                 <el-option v-for="(val,key) in albumType" :value="key" :label="val" :key="key"></el-option>
               </el-select>
             </div>
           </el-form-item>
<!--           <el-form-item label="专辑版本" prop="album_version">-->
<!--             <div class="content-item">-->
<!--               <el-select placeholder="请选择或输入专辑版本" v-model="value.album_version"-->
<!--                          filterable-->
<!--                          clearable-->
<!--                          allow-create>-->
<!--                 <el-option v-for="(val, key) in versionFn" :value="key" :label="val" :key="key" ></el-option>-->
<!--               </el-select>-->
<!--             </div>-->
<!--           </el-form-item>-->
           <el-form-item label="地区" prop="album_area">
             <div class="content-item">
               <el-select v-model="value.album_area">
                 <el-option v-for="(val, key) in albumArea" :value="key" :label="val" :key="key" ></el-option>
               </el-select>
             </div>
           </el-form-item>
           <el-form-item label="语言" prop="language">
             <div class="content-item">
               <el-select v-model="value.language" >
                 <el-option v-for="(val, key) in languageFn" :value="key" :label="val" :key="key" ></el-option>
               </el-select>
             </div>
           </el-form-item>
           <el-form-item label="外显厂牌公司" >
             <div class="content-item">
               <el-select placeholder="请选择或输入外显厂牌公司" v-model="value.brand_show_name" allow-create  default-first-option  filterable>
                 <el-option v-for="(val,key) in brandShowName" :label="val" :key="key" :value="val" />
               </el-select>
               <el-tooltip class="item" effect="dark" placement="top">
                 <div slot="content" style="width: 500px;line-height: 1.5">
                   外显只根据歌曲录音版权里的原始版权公司字段来展示，在“专辑外显”字段填上外显公司名，且同专辑的所有歌曲都需要保持原始版权公司为同一家。<br>
                   1. 当外显是你们主体公司时，歌曲的原始版权公司字段留空；<br>
                   2. 外显是其他公司，则在所有歌曲的原始版权公司字段填上其他公司名（如：独立发行）</div>
                 <el-image style="width: 14px;height: 14px;margin-left: 16px;" :src="require('../../../assets/doubt.png')"></el-image>
               </el-tooltip>
             </div>
           </el-form-item>
           <el-form-item label="发行时间" prop="issued_time">
             <div class="content-item">
               <el-date-picker
                   placeholder="请选择发行时间"
                   class="date-picker"
                   :style="{width:'450px'}"
                   value-format="yyyy-MM-dd HH:mm:ss"
                   v-model="value.issued_time"
                   type="datetime">
               </el-date-picker>
             </div>
           </el-form-item>
           <el-form-item label="专辑简介" prop="introduction">
             <div class="content-item">
               <el-input  :maxlength="1000"  placeholder="请输入10-1000文字" v-model="value.introduction" type="textarea"></el-input>
             </div>
           </el-form-item>
         </el-form>
         <div @click="isAway = !isAway" style="display: flex;align-items: center;justify-content: end; cursor: pointer;margin-right:60px;">
           <div style="font-size: 14px;font-family: PingFang SC, PingFang SC-Regular; font-weight: 400;color: #ff004d;">收起</div>
           <el-image style="width: 24px;height: 24px; transition: all .3s;" :class="{rotateClass:isAway}" :src="require('../../../assets/up-issue.png')"></el-image>
         </div>
       </div>
     </div>
    <artist-popup @determine="determine" ref="artistPopup"></artist-popup>
  </div>
</template>

<script>
import store from "@/store";
import {getSinger} from "@/api";
import hiCosUpload from "@/components/HiCosUpload";
import artistPopup from "@/views/administration/components/artistPopup";
let timer;
let timer2;
export default {
  name: "uploadAlbum",
  created() {
    this.subCompanyFn();
    this.getAdminList()
  },
  components:{
    hiCosUpload,
    artistPopup
  },
  props:{
    value:{
      type:Object,
      detail:()=>{
        return {
          cover_map_id:undefined,
          draft:0,//是否为草稿0:直接提交1：草稿
          album_id: 0,//专辑id
          subject_no: '',//版权公司
          album_name: '',//专辑名称
          singer_info:[],//专辑歌手
          introduction: '',//专辑简介
          issued_time:undefined,//专辑发行时间
          brand_show_name:undefined,//专辑外显公司名称
          language: '0',//语言
          album_area: '1',//地区
          album_type: '10',//专辑类型
          album_version:undefined,//专辑版本
        }
      }
    }
  },
  data() {
    return {
      isShow:true,
      imgUrl:undefined,
      isAway:false,
      choiceShow: false,
      newlyBuildShow:false,
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      companyList:[],
      loading: false,
      singer_infoOptions: [],
      rules:{
        subject_no:{required: true, message: '请填写公司名称', trigger: 'blur' },
        album_name:{required: true, message: '请输入专辑名称', trigger: 'blur' },
        singer_info:{required: true, message: '请输入所属艺人', trigger: 'blur' },
        introduction:[
            {required: true, message: '请输入专辑简介', trigger: 'blur' },
             { min: 10, max: 1000, message: '请输入10-500文字', trigger: 'blur' }
        ],
        issued_time:{required: true, message: '请输入发行时间', trigger: 'blur' },
        language:{required: true, message: '请输入语言', trigger: 'blur' },
        album_area:{required: true, message: '请输入地区', trigger: 'blur' },
        album_type:{required: true, message: '请输入专辑类型', trigger: 'blur' },
        cover_map_id:{required: true, message: '请输上传专辑封面', trigger: 'blur' },

      },
      albumList:[],
      page:1,
      size:15,
      name:'',

      singerQuery: '',
      singerPage: 1,
      singerHasMore: true
    }
  },
  computed:{
    brandShowName(){
      return this.$store.state.album ? this.$store.state.album.brand_show_name : []
    },
    albumType(){
      return this.$store.state.album ? this.$store.state.album.album_type : []
    },
    versionFn(){
      return this.$store.state.album ? this.$store.state.album.version : []
    },
    albumArea(){
      return this.$store.state.album ? this.$store.state.album.album_area : []
    },
    languageFn(){
      return this.$store.state.album ? this.$store.state.album.language : []
    },
    routerQuery(){
      return this.$route.name !== 'IssueQuery'
    },
    coverName() {
      return this.value.album_name ? this.value.album_name + '-封面' : undefined
    }
  },
  methods:{
    pullDown(e){
      if(e.target.className === 'title') this.choiceShow = !this.choiceShow;
    },
    searchFn(){
      clearTimeout(timer2)
      timer2 = setTimeout(()=>{
         this.size = 15;
         this.page = 1;
         this.albumList = [];
         this.getAdminList()
         this.choiceShow = true;
       },1000)
    },
    handleDelete(){
      this.$confirm('<p>删除后不可撤回，请确认是否删除?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'big-button',
        cancelButtonClass: 'big-button',
        dangerouslyUseHTMLString: true,
        center: true
      }).then(() => {
        this.empty()
        this.isAway = false
      }).catch(() => {});
    },
    handleAlbumBlur(){
      let name = this.value.album_name.replaceAll('（','(').replaceAll('）',')')
      const index = name.indexOf('(')
      const nameChar = name.split('')
      if (index > 0 && nameChar[index - 1] !== ' '){
        nameChar.splice(index, 0, ' ')
        this.value.album_name = nameChar.join('')
      }
    },
    onSuccessFn(){},
    beforeUpload(){},
    subCompanyFn(){
      this.$post('/api/issue/universal/album/subCompany').then(r => {
        if(r.code === 0){
          this.companyList = r.data;
          if(this.companyList.find(e => e.value === 'WHBL')) this.value.subject_no = 'WHBL'
        }
      })
     },
    getSinger(query = undefined) {
      if (query === undefined) {
        this.singerPage++
      } else {
        this.singerQuery = query
        this.singer_infoOptions = []
        this.singerPage = 1
        this.singerHasMore = true
      }

      getSinger({singerName: this.singerQuery, page: this.singerPage, size: 20}).then(r => {
        if (r.data.data.length < 20) {
          this.singerHasMore = false
        }

        this.singer_infoOptions = [
          ...this.singer_infoOptions,
          ...r.data.data.map(e => {
            return {
              "singer_name": e.singerName,
              "singer_id": e.tmeSingerId.toString(),
              "singer_pic": e.singerPic,
              page_q:e.qq_music_homepage,
              page_dy:e.douyin_homepage,
              page_wy:e.wangyi_homepage,
              fans_q:e.qq_music_fans,
              fans_kg:e.kugou_fans,
              fans_dy:e.douyin_fans,
              page_qs:e.qishui_homepage
            }
          })
        ]
        this.loading = false
      })
    },
    getAdminList(){
      this.$post('/api/issue/universal/album/relation',{page:this.page, size:this.size,name:this.name}).then(r => {
        if(r.code === 0){
          if(r.data.data.length > 0)++this.page
          if(this.size === 15)++this.size
          this.albumList.push(...r.data.data);
        }
      })
    },
    flag(val1,val2){
      this.$parent.status = 0
      this.newlyBuildShow = val1;
      this.choiceShow = val2;
    },
    empty(){
      this.newlyBuildShow = false;
      this.choiceShow = false;
      this.$emit('input',{
        cover_map_id:undefined,
        draft:0,//是否为草稿0:直接提交1：草稿
        album_id: 0,//专辑id
        subject_no: (this.companyList.find(e => e.value === 'WHBL')) ? this.value.subject_no = 'WHBL' : '',//版权公司
        album_name: '',//专辑名称
        singer_info:[],//专辑歌手
        introduction: '',//专辑简介
        issued_time:undefined,//专辑发行时间
        brand_show_name:undefined,//专辑外显公司名称
        language: '0',//语言
        album_area: '1',//地区
        album_type: '10',//专辑类型
        album_version:undefined,//专辑版本
      });
      this.imgUrl = undefined;
    },
    submit(){
    return new Promise((resolve)=>{
       this.$refs.form.validate(valid =>{
        resolve(valid);
       })
     })
    },
    determine(val){
      this.$post('/api/issue/universal/singer/show',{id:val}).then(r=> {
        if(r.code === 0){
          this.$message.success('新增艺人成功')
        }
      })
    },

    getScroll(event) {
      console.log('v-infinite-scroll');
      // 滚动条距离底部的距离scrollBottom
      let scrollBottom =
          event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight;
      if ( scrollBottom === 0) {
        clearInterval(timer)
        timer = setTimeout(()=>{
          this.getAdminList();
        },300)

      }
    },
  },
  watch:{
    value:{
      handler(val){
        this.$emit('input',val);
      },
      deep:true
    },
    'value.cover_map_id'(val){
      if (!val) {
        this.imgUrl = ''
        return
      }
      this.$post('/api/issue/universal/upload/previewFile',{file_id:val,type:0}).then(r => {
         this.imgUrl = r.data.url;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-singer-but{
  margin-left: 20px;
  width: 114px;
  height: 36px;
  padding: 0;
  border: none;
  background: rgba(255,0,77,.1);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #ff004d;
}
.upload-album {
  border-radius: 4px;
  padding: 20px 40px;
  background-color: #ffffff;

  .title {
    display: flex;
    align-items: center;

    .title-album {
      font-size: 15px;
    }

    .news-album {
      cursor: pointer;
      font-size: 12px;
      color: #ff004d;
      margin-left: 30px;
    }
  }

  .choice {
    cursor: pointer;
    height: 60px;
    width: 100%;
    background: #fcfcfc;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

    .left-icon {
      margin-right: 40px;
      width: 22px;
      height: 22px;
    }

    .title {
      flex: 1;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;
      ::v-deep .el-input__inner{
        border: none;
      }
    }

    .right-icon {
      width: 24px;
      height: 24px;
    }
  }

  .choice-album {
    height: 0px;
    background: #ffffff;
    border: 0.5px solid #dedede;
    border-radius: 4px;
    opacity: 0;
    transition: all .3s ease;
    display: flex;
    flex-flow: wrap;
    overflow-y: auto;
     .item{
       width: 90px;
       height: 150px;
       margin: 20px 25px 1px;
       border-radius: 2px;
       .newly{
         cursor: pointer;
         background: #fcfcfc;
         border: 1px dashed #dedede;
         width: 90px;
         height: 90px;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         font-size: 14px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #999999;
       }
       .title{
         height: 20px;
         width: 90px;
         display: block;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         font-size: 14px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #333333;

       }
       .subtitle{
         height: 20px;
         width: 90px;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         font-size: 12px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         text-align: LEFT;
         color: #666666;
       }
     }

  }
  .choice-album::-webkit-scrollbar {
    width: 10px;
  } // 设置滚动条整体样式

  .choice-album::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
  }
  .choice-album-active {
    height: 180px;
    opacity: 1;
  }

  .modify {
    .nav {
      border-radius: 4px 4px 0 0;
      width: 100%;
      height: 60px;
      background: #f3f3f3;
      line-height: 60px;
      padding: 0 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1){
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        flex: 1;
      }
      >div:nth-child(2){
        margin-right: 30px;
        div{
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ff004d;
        }
      }
      .rotateClass{
        transform: rotateZ(180deg);
      }
    }
    .content {
      border-radius: 0 0 4px 4px;
      padding-left: 60px;
      box-sizing: border-box;
      padding-top: 50px;
      height: 900px;
      transition:all .3s ease;
      background-color: #fcfcfc;
      ::v-deep .el-form{
        .el-form-item{
          .el-form-item__label{
            height: 35px;
            line-height: 35px;
            font-size: 14px;
            font-family: PingFang SC-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #555555;
          }
        }
      }
      .content-item {
        ::v-deep .el-input__inner {
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          width: 454px;
          height: 35px;
          background: #ffffff;
          padding-left: 15px;
          box-sizing: border-box;
          &::placeholder{
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #999999;
          }
        }
        ::v-deep .el-textarea{
          width: 454px;
        }
        ::v-deep .el-textarea__inner {
          width: 454px;
          height: 111px;
          background: #ffffff;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          &::placeholder{
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #999999;
          }
        }
         .date-picker{
           ::v-deep .el-input__inner{
             padding-left: 30px;
           }
         }
      }

      .uploadImg {
        width: 130px;
        height: 130px;
        border-radius: 4px;
      }

      .uploadBut {
        margin-left: 20px;

        .uploadBut-header {
          width: 250px;
          height: 44px;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
          line-height: 22px;
          margin-bottom: 20px;
        }

        .el-upload {
          .el-button {
            width: 80px;
            height: 31px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 4px;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #555555;
          }
          .active:hover{
            border: 1px solid #ff004d;
            border-radius: 4px;
            color: #FF004D;
          }
        }
      }

    }
    .content-active{
      transition:all .3s ease;
      height: 0;
      padding-top: 0;
      overflow: hidden;
    }
  }
}
</style>
