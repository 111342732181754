<template>
  <div style="display: inline-flex;flex-direction: column">
    <el-upload
        :disabled="disabled"
        multiple
        action="/"
        :accept="accept"
        :drag="drag"
        :limit="5"
        :before-upload="upload">
      <slot>
        <div class="upload">
          <div class="default-text">
            <el-image :src="uploadImage" style="width: 16px;height: 16px;margin-right: 8px"></el-image>{{ text }}
          </div>
        </div>
      </slot>
    </el-upload>
    <upload-item :file-type="fileType" v-for="(item, index) in files" v-model="files[index]" :key="item" @delete="handleDelete" :disabled="disabled"></upload-item>

    <el-progress :percentage="progress"  v-if="progress !== 100" style="margin-top: 15px"></el-progress>
  </div>
</template>

<script>
import uploadItem from "@/components/HiUpload/uploadItem"
import uploadImage from "@/assets/upload/upload.png"
import moment from "moment/moment"
import COS from "cos-js-sdk-v5"

export default {
  name: "HiUpload",

  components: {
    uploadItem
  },

  props: {
    douyin: {
      type: Boolean,
      default: false
    },
    kuaishou: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    disabled:{
      type:Boolean,
      default:false,
    },
    accept: {
      type: String,
      default: '*/*'
    },
    drag: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: '点击或拖拽'
    },
    showLoading: {
      type: Boolean,
      default: true
    },
    fileType: {
      type: String,
      default: 'other' // 'contract'
    },
    /**
     * 文件类别【3歌词txt，4封面，7片段-主歌，8片段-其他，10伴奏-无和声，11原曲，12伴奏-和声，14封面源文件，其他的0】
     */
    kind: {
      type: Number,
      default: 0
    },
    baseInfo:{
      type:Object,
      default:()=>{}
    },
  },

  data() {
    return {
      uploadImage,
      files: [],

      loading: undefined,
      uploadedCount: 0,
      uploadCount: 0,
      progress1: 100,

      fileIndex: 0
    }
  },

  mounted() {
    if(this.value){
      this.files = this.value.toString().split(',')
      this.fileIndex = this.files.length
    }
  },

  methods: {
    upload(file){
      this.uploadCount++
      if (this.showLoading) this.loading = this.$loading({
        lock: true,
        text: '文件上传中。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const config = {
        contract: {
          type: 'abc',
          bucket: process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development' ? 'legal-test-1305250541' : 'legal-online-1305250541'
        },
        other: {
          type: 'material',
          bucket: 'legal-1305250541'
        }
      }

      let Region = "ap-shanghai";
      let dir = 'albums/material/' + moment().format('YYYY-MM-DD/x');
      let bucket = config[this.fileType].bucket;
      let type= config[this.fileType].type;
      const that = this
      let cos = new COS({
        async getAuthorization(options, callback) {
          let url = '/api/issue/universal/upload/getCosToken'; // 这里替换成您的服务接口地址
          await that.$post(url, {
            dir,
            type
          }).then(r => {
            let data = r.data
            let credentials = r.data.credentials

            if (!data || !credentials) return console.error('credentials invalid');
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              XCosSecurityToken: credentials.sessionToken,
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000900
              ScopeLimit: true,
            });
          })
        }
      });

      //bucket,合同测试使用"legal-test-1305250541"，线上使用"legal-online-1305250541"
      //其他歌曲相关物料使用"legal-1305250541"

      let file_key = dir + '/' + file.name
      cos.sliceUploadFile({
        Bucket: bucket,
        Region: Region,
        Key: file_key,
        StorageClass: 'STANDARD',    //'STANDARD_IA'  ,无损音频使用'STANDARD_IA'
        // ACL:'public-read',
        Body: file,
        onProgress: this.onProgress,
      }, (err, data) => {
        if (data) {
          this.saveFile({
            type: that.fileType === 'contract' || this.cover ? 0 : 1,
            name: this.setName(file.name),
            kind: that.kind,
            dir,
            url: data.Key,
            location: data.Location,
            mime: file.type || 'file',
            size: file.size
          })
        } else {
          this.$popup({msg: "文件上传失败"});
        }
      });

      return false
    },
    saveFile(data){
      this.$post('/api/issue/universal/upload/saveFile', data).then(r => {
        this.files.push(r.data.file_id.toString())
        this.$emit('input', this.files.join(','), data.name)
        this.uploadedCount++
      }).catch(e => {
        console.error(e)
        this.progress1 = 100
      })
    },
    onProgress (progressData) {
      if (this.showLoading && this.loading) this.loading.close()

      this.progress1 = parseInt((progressData["percent"] * 100).toString())
    },
    handleDelete(fileId){
      const index = this.files.indexOf(fileId)
      if (index !== -1){
        this.files.splice(index, 1)
        this.$emit('input', this.files.join(','))
      }
    },
    setName(val){
      let index = val.lastIndexOf('.');
      let m = val.substring(index, val.length)
      if (this.coverName) return this.coverName + m
      if (this.cover || this.fileType === 'contract') return val

      let singers = []
      singers = this.baseInfo.singer_sources ? this.baseInfo.singer_sources.map(e => e.singerName) : [];
      const songVersion = this.baseInfo.song_version ? ' (' + this.baseInfo.song_version + ')' : ''
      if (this.douyin){
        this.fileIndex++
        return `${this.baseInfo.song_name + songVersion}-${singers.join(',')}-推广片段-抖音${ + this.fileIndex+ m}`
      }
      if (this.kuaishou){
        this.fileIndex++
        return `${this.baseInfo.song_name + songVersion}-${singers.join(',')}-推广片段-快手${ + this.fileIndex+ m}`
      }
      let type_str = '';
      switch (this.kind){   //10,11,12,14,3,7,8
        case 3:type_str = '歌词';break;
        case 7:type_str = '片段';break;
        case 8:type_str = '片段-其他';break;
        case 10:type_str = '伴奏-无和声';break;
        case 11:type_str = '原曲';break;
        case 12:type_str = '伴奏-和声';break;
        case 14:type_str = '封面源文件';break;
        default:type_str = '文件'
      }

      if(!this.baseInfo.song_name) return  val
      else if (singers.length > 0) return this.baseInfo.song_name + songVersion + '-' + singers.join(',') + '-' + type_str + m
      else return this.baseInfo.song_name + songVersion + '-' + type_str + m
    }
  },

  computed: {
    progress(){
      if (this.uploadCount > 1) return this.uploadCount === 0 ? 100 :  parseInt((100 * this.uploadedCount / this.uploadCount).toString())
      else return this.progress1
    }
  },

  watch: {
    uploadedCount(val) {
      if (val !== 0 && val === this.uploadCount){
        this.uploadedCount = 0
        this.uploadCount = 0
      }
    },
    value(){
      this.files = this.value === '' ? [] : this.value.toString().split(',')
    }
  }
}
</script>

<style scoped lang="scss">
.upload{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fcfcfc;
  height: 60px;
}
::v-deep .el-upload-dragger{
  height: 60px;
  display: flex;
  align-items: center;
}
.big ::v-deep .upload{
  height: 100px;
}
.big ::v-deep .el-upload-dragger{
  height: 100px;
  width: 100%;
}
.big ::v-deep .el-upload{
  width: 100%;
}
.upload-progress{
  width: 0;
  height: 60px;
  position: absolute;
  background: rgba(107,175,11,0.30);
}
.default-text{
  font-size: 12px;
  color: #ff004d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
::v-deep .el-progress{
  width: 100%;
  display: flex;
  align-items: center;
  .el-progress-bar{
    margin-right: -25px!important;
    padding-right: 27px!important;
  }
  .el-progress-bar__outer{
    height: 2px !important;
  }
  .el-progress__text{
    font-size: 10px!important;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #333333;
    width: 20px;
  }

}
</style>
