<template>
  <div style="padding-left: 60px;padding-top: 20px">
    <h5>原始版权信息</h5>
    <el-row :gutter="15">
      <el-form ref="elForm" :disabled="queryIssue" :model="formData" :rules="rules" size="medium" label-width="140px" label-position="left">
        <el-col :span="24">
          <el-form-item label="版权合同文件" prop="id_contract_files">
            <div style="display: flex">
              <hi-upload :base-info="baseInfo" v-model="formData.id_contract_files" file-type="contract" style="display: inline-block" accept=".doc,.docx,.pdf"></hi-upload>
              <div style="font-size: 10px;color: #999999;display: inline-block;margin-left: 14px;padding-top: 36px">支持word、pdf文件，可上传多个</div>
            </div>

          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="合同文件是否齐全" prop="file_complete">
            <el-radio-group v-model="formData.file_complete" size="medium">
              <el-radio v-for="(item, index) in file_completeOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注"
                      :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-form-item label="词著作权" prop="lyrics_right" style="margin-bottom: 10px">
            <el-radio-group v-model="formData.lyrics_right" size="medium">
              <el-radio v-for="(item, index) in lyrics_rightOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="formData.lyrics_right >= 2">
            <div style="background-color: #f5f5f5;border-radius: 4px;padding: 10px 24px">
              <el-date-picker

                  v-model="formData.lyrics_right_dateValue" value-format="yyyy-MM-dd" type="daterange" placeholder="请选择表演权代理/授权有效期" style="margin-right: 15px" :disabled="formData.lyrics_right_type === 1"></el-date-picker>
              <el-checkbox v-model="formData.lyrics_right_type" :true-label="1" :false-label="0"
                           @change="formData.lyrics_right_dateValue = formData.lyrics_right_type === 1 ? [] : formData.lyrics_right_dateValue">永久</el-checkbox>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-form-item label="曲著作权" prop="tune_right" style="margin-bottom: 10px">
            <el-radio-group v-model="formData.tune_right" size="medium">
              <el-radio v-for="(item, index) in tune_rightOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="formData.tune_right >= 2">
            <div style="background-color: #f5f5f5;border-radius: 4px;padding: 10px 24px">
              <el-date-picker
                  v-model="formData.tune_right_dateValue" value-format="yyyy-MM-dd" type="daterange" placeholder="请选择表演权代理/授权有效期" style="margin-right: 15px" :disabled="formData.tune_right_type === 1"></el-date-picker>
              <el-checkbox v-model="formData.tune_right_type" :true-label="1" :false-label="0"
                           @change="formData.tune_right_dateValue = formData.tune_right_type === 1 ? [] : formData.tune_right_dateValue">永久</el-checkbox>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-form-item label="表演者权" prop="performer_right" style="margin-bottom: 10px">
            <el-radio-group v-model="formData.performer_right" size="medium">
              <el-radio v-for="(item, index) in performer_rightOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="formData.performer_right >= 2">
            <div style="background-color: #f5f5f5;border-radius: 4px;padding: 10px 24px">
              <el-date-picker
                v-model="formData.performer_right_dateValue" value-format="yyyy-MM-dd" type="daterange" placeholder="请选择表演权代理/授权有效期" style="margin-right: 15px" :disabled="formData.performer_right_type === 1"></el-date-picker>
              <el-checkbox v-model="formData.performer_right_type" :true-label="1" :false-label="0"
                           @change="formData.performer_right_dateValue = formData.performer_right_type === 1 ? [] : formData.performer_right_dateValue">永久</el-checkbox>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="15">
          <el-form-item label="录音制作权" prop="tape_right" style="margin-bottom: 10px">
            <el-radio-group v-model="formData.tape_right" size="medium">
              <el-radio v-for="(item, index) in tape_rightOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="formData.tape_right >= 2">
            <div style="background-color: #f5f5f5;border-radius: 4px;padding: 10px 24px">
              <el-date-picker
                v-model="formData.tape_right_dateValue"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                placeholder="请选择录音制作权代理/授权有效期"
                style="margin-right: 15px"
                :disabled="formData.tape_right_type === 1"></el-date-picker>
              <el-checkbox v-model="formData.tape_right_type" :true-label="1" :false-label="0"
                           @change="formData.tape_right_dateValue = formData.tape_right_type === 1 ? [] : formData.tape_right_dateValue">永久</el-checkbox>
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>

  </div>
</template>
<script>
import copyrightInfo from "@/views/issue/components/copyrightInfoForm/copyrightInfo"
import HiUpload from "@/components/HiUpload"


export default {
  name: 'CopyrightInfoForm',

  components: {
    HiUpload
  },

  props: {
    queryIssue: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    baseInfo:{
      type:Object,
      default:()=>{}
    }
  },

  mounted() {
    this.formData = this.value
  },

  data() {
    return {
      pickerOptions:{                                 //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      formData: copyrightInfo,
      rules: {
        file_complete: [],
        remark: [],
        tape_right: [{
          required: true,
          message: '录音制作权不能为空',
          trigger: 'change'
        }],
        performer_right: [{
          required: true,
          message: '表演者权不能为空',
          trigger: 'change'
        }],
        lyrics_right: [{
          required: true,
          message: '词著作权不能为空',
          trigger: 'change'
        }],
        tune_right: [{
          required: true,
          message: ' 曲著作权不能为空',
          trigger: 'change'
        }],
        auth_form1: [{
          required: true,
          message: '授权形式（录音）不能为空',
          trigger: 'change'
        }],
        wyy_copyright_end_time1: [{
          required: false,
          message: ' ',
          trigger: 'change'
        }],
        first_period_day1: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        other_platform: [{
          required: true,
          message: ' ',
          trigger: 'change'
        }],
        dy_auth_form: [{
          required: true,
          message: '不能为空',
          trigger: 'change'
        }],
        ks_auth_form: [{
          required: true,
          message: '不能为空',
          trigger: 'change'
        }],
      },
      file_completeOptions: [{
        "label": "是",
        "value": 1
      }, {
        "label": "否",
        "value": 0
      }],
      tape_rightOptions: [{
        "label": "无",
        "value": 0
      }, {
        "label": "自有",
        "value": 1
      }, {
        "label": "代理",
        "value": 2
      }],
      performer_rightOptions: [{
        "label": "无",
        "value": 0
      }, {
        "label": "自有",
        "value": 1
      }, {
        "label": "代理",
        "value": 2
      }],
      lyrics_rightOptions: [{
        "label": "无",
        "value": 0
      }, {
        "label": "自有",
        "value": 1
      }, {
        "label": "代理",
        "value": 2
      }, {
        "label": "授权",
        "value": 4
      }],
      tune_rightOptions: [{
        "label": "无",
        "value": 0
      }, {
        "label": "自有",
        "value": 1
      }, {
        "label": "代理",
        "value": 2
      }, {
        "label": "授权",
        "value": 4
      }],
      auth_form1Options: [{
        "label": "重点版权",
        "value": 1,
        content: '独家，仅上TME'
      }, {
        "label": "首发版权",
        "value": 2,
        content: '首发X天后，上线全网'
      }, {
        "label": "普通版权",
        "value": 3,
        content: '非独家'
      }],
      other_platformOptions: [{
        "label": "抖音",
        "value": 2
      }, {
        "label": "快手",
        "value": 3
      }, {
        "label": "网易云",
        "value": 1
      }],
      dy_auth_formOptions: [{
        "label": "短视频非独家",
        "value": 0
      }, {
        "label": "短视频独家",
        "value": 1
      }],
      ks_auth_formOptions: [{
        "label": "短视频非独家",
        "value": 0
      }, {
        "label": "短视频独家",
        "value": 1
      }],
    }
  },

  computed: {
    other_platformOptionsComputed() {
      if (this.formData.auth_form1 === 1) {
        return [this.other_platformOptions[0], this.other_platformOptions[1]]
      } else {
        return this.other_platformOptions
      }
    }
  },

  watch: {
    value(val) {
      this.formData = val
    },
    'formData.other_platform'(val) {
      if(!val.find(e => e === 3)) this.formData.ks_auth_form = null
      if(!val.find(e => e === 2)) this.formData.dy_auth_form = null
    },
    'formData.auth_form1'(val) {
      if (val === 1){
        this.formData.is_qishui = 0
        const index = this.formData.other_platform.indexOf(1)
        if (index !== -1) {
          this.formData.other_platform.splice(index, 1)
        }
      }
    }
  },

  created() {},

  methods: {
    handleChangeKS(e){
      if (e === 1) {
        this.formData.dy_auth_form = null
        const index = this.formData.other_platform.indexOf(2)
        if (index !== -1) {
          this.formData.other_platform.splice(index, 1)
        }
      }
    },
    handleChangeDY(e){
      if (e === 1) {
        this.formData.ks_auth_form = null
        const index = this.formData.other_platform.indexOf(3)
        if (index !== -1) {
          this.formData.other_platform.splice(index, 1)
        }
      }
    },
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}

</style>
