<template>
  <div class="draft-popup">
    <div @click="show = true;"
         style="font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: LEFT;color: #FF004D;cursor: pointer;margin: 24px 0;padding-left: 40px">
      从草稿箱中上传({{ total }}) >
    </div>
    <el-dialog custom-class="dialog-draft" center width="700px" :visible.sync="show">
      <div slot="title">从草稿箱中选择({{ total }})</div>
      <el-table style="min-height: 500px" :data="list" header-cell-class-name="header-table-draft-popup"  row-class-name="table__cell" >
        <el-table-column width="58px" class-name="select">
          <template v-slot:default="scope">
            <div style="display: flex;align-items: center;justify-content: center">
              <el-image @click="selectFn(scope.row)" style="width: 18px;height: 18px;cursor: pointer"
                        :src="publicId === scope.row.id ? SelectIcon :Unchecked"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="专辑" prop="album_name"></el-table-column>
        <el-table-column label="歌曲" :formatter="songMatter"></el-table-column>
      </el-table>
      <div style="text-align: center;margin-top: 20px;margin-bottom: 24px;">
        <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            @current-change="query"
            :page-size="params.size"
            :total="total">
        </el-pagination>
      </div>
      <span slot="footer">
      <el-button class="el-button-draft-popup" @click="show = false">取消</el-button>
      <el-button :disabled="publicId === undefined" class="el-button-draft-popup el-button-draft-popup-active" @click="confirm"
                 type="primary">确定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectIcon from '@/assets/SelectIcon.png'
import Unchecked from '@/assets/Unchecked.png'

export default {
  name: "hiDraftPopup",
  data() {
    return {
      show: false,
      params: {
        page: 1,
        size: 10,
        status_ids: [0]
      },
      total: 0,
      list: [],
      SelectIcon,
      Unchecked,
      publicId: undefined,
      ver: undefined,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$post('/api/issue/universal/album/list', this.params).then(r => {
        if (r.code === 0) {
          this.list = r.data.data;
          this.total = r.data.total;
        }
      })
    },
    query(val) {
      this.params.page = val;
      this.getList();
    },
    songMatter(row) {
      if (row.applys.length === 0) {
        return '-'
      } else if (row.applys.length === 1) {
        return row.applys[0].name
      } else {
        return `${row.applys[0].name}...等${row.applys.length}首`
      }
    },
    selectFn(row) {
      if (this.publicId === row.id) {
        this.publicId = undefined;
        this.ver = undefined;
      } else {
        this.publicId = row.id;
        this.ver = row.ver;
      }
    },
    confirm() {
      this.$emit('selectDraft', this.publicId, this.ver)
      this.show = false;
      this.publicId = undefined;
      this.ver = undefined;
    },
    refresh() {
      this.params.page = 1;
      this.getList();
    }
  }
}
</script>

<style lang="scss">
.draft-popup {

}

.header-table-draft-popup {
  height: 49px;
  background: #f7f7f7 !important;
  border-radius: 3px;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #222222;
  line-height: 18px;
}
 .el-table__row{
  .el-table__cell{
    padding: 12px 0;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    border: none !important;
  }
}
 .el-button-draft-popup {
  width: 188px;
  height: 42px;
  background: #f7f7f7;
  border-radius: 3px;
  font-size: 15px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #666666;
}

.el-button-draft-popup-active {
  color: #ffffff;
  background: #ff004d;
}

.dialog-draft {
  box-sizing: border-box;
  border-radius: 10px;
  .el-dialog__header {
    padding-top: 46px;
    padding-bottom: 30px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #222222;
  }

  .el-dialog__body {
    padding: 0 40px;
  }
}
</style>
