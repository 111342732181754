<template>
  <div class="song-details">
    <div class="song-name" v-if="isModify" >
      <el-image :src="selectIcon" style="width: 14px;height: 14px;margin-right: 6px;"></el-image>
      <div class="name" :title="filename">{{filename}}</div>
      <div class="right-icon" v-if="!routerQuery && !disabled">
        <el-image @click="modifyFn" :src="require('@/assets/modify-issue-icon.png')" style="width: 14px;height: 14px; margin-right: 17px;" ></el-image>
        <el-image @click="handleDelete()" :src="require('@/assets/delete-issue-icon.png')" style="width: 14px;height: 14px;" ></el-image>
      </div>
    </div>
    <div class="song-name-modify" v-else>
      <el-image :src="selectIcon" style="width: 14px;height: 14px;margin-right: 6px;"></el-image>
      <div class="name">
        <el-input v-model="fileNameArray[0]" >
          <span class="el-suffix" slot="suffix">{{fileNameArray[1]}}</span>
        </el-input>
      </div>
      <div class="right-icon" v-if="!routerQuery">
        <el-image @click="confirm" :src="require('@/assets/determine-issue-icon.png')" style="cursor: pointer; width: 14px;height: 14px; margin-right: 17px;" ></el-image>
        <el-image @click="isModify = true;fileNameArray = [];" :src="require('@/assets/colse-issue-icon.png')" style="width: 14px;height: 14px;cursor: pointer;" ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import issueAudioIcon from "@/assets/issue-audio-icon.png"
import songFileIcon from "@/assets/song-file-icon.png"

export default {
  name: "uploadItem",

  props: {
    value: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: 'other' // 'contract'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      issueAudioIcon,
      songFileIcon,
      filename: '',
      isModify :true,
      fileNameArray:[],
    }
  },

  mounted() {
    this.$post('/api/issue/universal/upload/previewFile',{type:this.fileType === 'other' ? 1 : 0, file_id: this.value}).then(r => {
      if(r.code === 0){
        this.filename = r.data?.name;
      }
    })
  },

  computed:{
    routerQuery(){
      return this.$route.name === 'IssueQuery'
    },
    selectIcon(){
      if(this.kind === 7 || this.kind === 8 || this.kind === 8 || this.kind === 9 || this.kind === 10 || this.kind === 11 || this.kind === 12){
        return this.issueAudioIcon
      } else {
        return this.songFileIcon
      }
    }
  },

  methods: {
    modifyFn(){
      this.isModify = false;
      let index = this.filename.lastIndexOf('.');
      this.fileNameArray[0] = this.filename.substring(0,index)
      this.fileNameArray[1] = this.filename.substring(index,this.filename.length)
    },
    handleDelete(){
      this.$emit('delete', this.value)
    },
    confirm(){
      this.$post('/api/issue/universal/upload/renameFile',{
        type: this.fileType === 'contract' ? 0 : 1,
        id: this.value,
        name:this.fileNameArray[0] + this.fileNameArray[1]
      }).then(r => {
        if(r.code === 0){
          this.filename = this.fileNameArray[0] + this.fileNameArray[1]
          this.fileNameArray = [];
          this.isModify = true;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">

.upload-content{
  padding: 17px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.file-name, .action{
  align-items: center;
  font-size: 12px;
  color: #333333;
  overflow: hidden;
  width: 200px;
  display: block;
  text-overflow: ellipsis;
  text-align: left;
}
.song-details{
  padding-top: 12px;
}
.song-details >.song-name{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 29px;
  padding:0 4px;
  .right-icon{
    opacity: 0;
    display: flex;
    align-items: center;

  }
  &:hover{
    background-color: rgba(255,0,77,.1);
    cursor: pointer;
    .right-icon{
      opacity: 1;
    }

  }
}
.song-details > .song-name-modify{
  display: flex;
  justify-content:center;
  align-items: center;
  height: 29px;
  padding:0 4px;
  flex: 1;
  border: 1px solid #cecece;
  .right-icon{
    display: flex;
    align-items: center;
  }
}

.song-details .name {
  flex: 1;
  font-size: 12px;
  max-width: 280px;
  line-height: 1.25;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::v-deep .el-input{
    .el-input__suffix{
      display: block;
      .el-input__suffix-inner{
        line-height: 2.4;
      }
    }
    .el-input__inner{
      border: none;
      height: 29px;
      padding-right: 40px;
    }
  }

}
</style>
