<template>
<div>
  <div style="padding-left: 60px;padding-top: 20px;padding-right: 60px">
    <el-form label-width="140px" label-position="left" size="medium" :model="formData">
      <h3>授权发行TME</h3>
      <el-form-item label="授权形式（录音）" prop="auth_form1" :disabled="readonly">
        <el-radio-group v-model="formData.auth_form1" size="medium" :disabled="readonly" @change="handleAuthForm">
          <el-radio v-for="(item, index) in auth_form1Options" :key="index" :label="item.value"
                    :disabled="item.disabled">
            {{item.label}}
            <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom">
              <el-image style="width: 14px;height: 14px;margin-left: 6px;margin-bottom: -2px" :src="require('../../../../assets/doubt.png')"></el-image>
            </el-tooltip>
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-col v-show="formData.auth_form1 === 3">
        <el-form-item label="网易云版权到期时间（录音）" prop="wyy_copyright_end_time1" label-width="200px">
          <div style="width: 400px;display: inline-block">
            <el-date-picker
                 :disabled="readonly"
                type="datetime" v-model="formData.wyy_copyright_end_time1"
                format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :style="{width: '100%'}"
                placeholder="请选择网易云版权到期时间（录音）" clearable></el-date-picker>
          </div>

        </el-form-item>
      </el-col>
      <el-col v-show="formData.auth_form1 === 2">
        <el-form-item label="首发天数" prop="first_period_day1" required>
          <el-input-number v-model="formData.first_period_day1" :min="1" :max="30" :disabled="readonly" @change="handleAuthForm(-1)"
                           label="首发天数"></el-input-number>
        </el-form-item>
      </el-col>

      <h3>授权发行其他平台</h3>


        <div class="platform" v-show="formData.auth_form1 !== 1">
          <el-checkbox :label="3" v-model="formData.is_wangyi" :true-label="1" :false-label="0" :disabled="readonly" @change="handleChangeWY">网易云</el-checkbox>
        </div>
        <el-form-item label="授权开始时间" required v-show="formData.auth_form1 !== 1 && formData.is_wangyi">
          <el-date-picker v-model="formData.wyy_auth_start_time" type="datetime" placeholder="授权开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :readonly="formData.auth_form1 === 2"></el-date-picker>
        </el-form-item>
      <el-form-item label="授权结束时间" required v-if="formData.wyy_auth_end_time">
        {{ formData.wyy_auth_end_time }}
      </el-form-item>
      <el-form-item label="平台链接" v-if="formData.wyy_file_url && formData.wyy_file_url?.length !== 0">
        <div style="padding-left: 10px">
          <div v-for="(item, index) in formData.wyy_file_url" :key="index">
            <div v-html="filtrateFn(item)"></div>
          </div>
        </div>
      </el-form-item>

        <div class="platform" v-show="formData.auth_form1 !== 1">
          <el-checkbox :label="3" v-model="formData.is_qishui" :true-label="1" :false-label="0" :disabled="readonly" @change="handleChangeQS">汽水音乐</el-checkbox>
        </div>
        <el-form-item label="授权开始时间" required v-show="formData.auth_form1 !== 1 && formData.is_qishui">
          <el-date-picker v-model="formData.qs_auth_start_time" type="datetime" placeholder="授权开始时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :readonly="formData.auth_form1 === 2"></el-date-picker>
        </el-form-item>
      <el-form-item label="授权结束时间" required v-if="formData.qs_auth_end_time">
        {{ formData.qs_auth_end_time }}
      </el-form-item>
      <el-form-item label="平台链接" v-if="formData.qs_file_url && formData.qs_file_url?.length !== 0">
        <div style="padding-left: 10px">
          <div v-for="(item, index) in formData.qs_file_url" :key="index">
            <div v-html="filtrateFn( item)"></div>
          </div>
        </div>
      </el-form-item>

        <div class="platform">
          <el-checkbox v-model="formData.is_douyin" :true-label="1" :false-label="0" :disabled="formData.ks_auth_form === 1 || readonly" @change="handleChangeDY">抖音</el-checkbox>
        </div>
        <template v-if="formData.is_douyin === 1">
          <el-form-item label="授权开始时间" required>
            <el-date-picker v-model="formData.dy_auth_start_time" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="授权开始时间" :readonly="readonly"></el-date-picker>
          </el-form-item>
          <el-form-item label="授权结束时间" required v-if="formData.dy_auth_end_time">
            {{ formData.dy_auth_end_time }}
          </el-form-item>
          <el-form-item label="授权形式" required>
            <el-radio-group v-model="formData.dy_auth_form" @change="handleDY" :disabled="readonly">
              <el-radio v-for="(item, index) in dy_auth_formOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推广片段" required>
            <div style="display: flex">
              <hi-upload :base-info="baseInfo" v-model="materialInfo.id_file_7" :kind="7" style="display: inline-block" accept=".mp3,.wav" douyin :disabled="readonly"></hi-upload>
              <div style="font-size: 10px;color: #999999;display: inline-block;margin-left: 14px;padding-top: 36px">支持mp3，wav文件，可上传多个</div>
            </div>
          </el-form-item>
          <el-form-item label="平台链接" v-if="formData.dy_part_file_url && formData.dy_part_file_url?.length !== 0">
            <div style="padding-left: 10px">
              <div v-for="(item, index) in formData.dy_part_file_url" :key="index">
                <div v-html="filtrateFn(  item)"></div>
              </div>
            </div>
          </el-form-item>
        </template>

        <div class="platform">
          <el-checkbox v-model="formData.is_kuaishou" :true-label="1" :false-label="0" :disabled="formData.dy_auth_form === 1 || readonly" @change="handleChangeKS">快手</el-checkbox>
        </div>
        <template v-if="formData.is_kuaishou === 1">
          <el-form-item label="授权开始时间" required>
            <el-date-picker v-model="formData.ks_auth_start_time" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="授权开始时间" :readonly="readonly"></el-date-picker>
          </el-form-item>
          <el-form-item label="授权结束时间" required v-if="formData.ks_auth_end_time">
            {{ formData.ks_auth_end_time }}
          </el-form-item>
          <el-form-item label="授权形式" required>
            <el-radio-group v-model="formData.ks_auth_form" @change="handleKS" :disabled="readonly">
              <el-radio v-for="(item, index) in ks_auth_formOptions" :key="index" :label="item.value"
                        :disabled="item.disabled">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="推广片段" required>
            <div>
              <el-checkbox v-model="formData.ks_use_dy_file" :true-label="1" :false-label="0" :disabled="readonly || !formData.is_douyin">同抖音推广片段</el-checkbox>
              <span style="font-size: 10px;color: #999999"> 勾选后，将使用抖音推广片段，如有额外片段，可通过下方添加</span>
            </div>
            <div style="display: flex;margin-top: 5px">
              <hi-upload :base-info="baseInfo" v-model="materialInfo.id_file_16" :kind="16" style="display: inline-block" accept=".mp3,.wav" kuaishou :disabled="readonly"></hi-upload>
              <div style="font-size: 10px;color: #999999;display: inline-block;margin-left: 14px;padding-top: 36px">支持mp3，wav文件，可上传多个</div>
            </div>
          </el-form-item>
          <el-form-item label="平台链接" v-if="formData.ks_part_file_url && formData.ks_part_file_url?.length !== 0">
            <div style="padding-left: 10px">
              <div v-for="(item, index) in formData.ks_part_file_url" :key="index">
                <div v-html="filtrateFn(  item)"></div>
              </div>
            </div>
          </el-form-item>
        </template>
    </el-form>
  </div>
</div>
</template>

<script>
import HiUpload from "@/components/HiUpload"
import copyrightInfo from "@/views/issue/components/copyrightInfoForm/copyrightInfo"
import moment from "moment"

export default {
  //授权平台信息
  name: "platform-information",

  components: {
    HiUpload
  },

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    baseInfo:{
      type:Object,
      default:()=>{}
    },
    materialInfo:{
      type:Object,
      default:()=>{}
    }
  },

  mounted() {
    this.formData = this.value
  },

  data(){
    return {
      pickerOptions:{                                 //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      formData: {
        ...copyrightInfo,
        other_platform: [],
        id_file_7: '',
        id_file_16: ''
      },
      auth_form1Options: [{
        "label": "重点版权",
        "value": 1,
        content: '独家，仅上TME'
      }, {
        "label": "首发版权",
        "value": 2,
        content: '首发X天后，上线全网'
      }, {
        "label": "普通版权",
        "value": 3,
        content: '非独家'
      }],
      dy_auth_formOptions: [{
        "label": "短视频非独家",
        "value": 0
      }, {
        "label": "短视频独家",
        "value": 1
      }],
      ks_auth_formOptions: [{
        "label": "短视频非独家",
        "value": 0
      }, {
        "label": "短视频独家",
        "value": 1
      }],
    }
  },

  methods: {
    fn(url){
      window.open(url);
    },
    filtrateFn(item ){
     console.log(item,'item')
      let z =/(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g
      let p = item.match(z)
      return `<a href="${p}" target="_blank">${item}</a>`

    },
    handleAuthForm(e){
      console.log('handleAuthForm')
      if (e === 1){
        this.formData.wyy_auth_start_time = ''
        this.formData.qs_auth_start_time = ''
        this.formData.is_qishui = 0
        this.formData.is_wangyi = 0
      } else if (e === 2){
        this.formData.wyy_auth_start_time = this.formData.is_wangyi ? this.startTime() : ''
        this.formData.qs_auth_start_time = this.formData.is_qishui ? this.startTime() : ''
      } else if(e === 3){
        this.formData.wyy_auth_start_time = this.formData.is_wangyi ? this.issueTime() : ''
        this.formData.qs_auth_start_time = this.formData.is_qishui ? this.issueTime() : ''

      } else if(e === -1){
        this.formData.wyy_auth_start_time = this.formData.is_wangyi ? this.startTime() : ''
        this.formData.qs_auth_start_time = this.formData.is_qishui ? this.startTime() : ''

      }
    },
    handleChangeWY(e){
      console.log('handleChangeWY')
      if (e === 1 && !this.formData.wyy_auth_start_time) {
        this.formData.wyy_auth_start_time = this.startTime()
      }
    },
    handleChangeQS(e){
      console.log('handleChangeQS')
      if (e === 1 && !this.formData.qs_auth_start_time) {
        this.formData.qs_auth_start_time = this.startTime()
      }
    },
    handleChangeDY(e){
      console.log('handleChangeDY')
      if (e === 1 && !this.formData.dy_auth_start_time) {
        this.formData.dy_auth_start_time = this.issueTime()
      } else if (e === 0) {
        this.formData.ks_use_dy_file = 0
      }
    },
    handleChangeKS(e){
      console.log('handleChangeKS', e === 1, !this.formData.ks_auth_start_time)
      if (e === 1 && !this.formData.ks_auth_start_time) {
        this.formData.ks_auth_start_time = this.issueTime()
      }
    },
    handleDY(e){
      console.log('handleDY')
      if (e === 1) {
        this.formData.ks_auth_form = null
        this.formData.is_kuaishou = 0
      }
    },
    handleKS(e){
      console.log('handleKS')
      if (e === 1) {
        this.formData.dy_auth_form = null
        this.formData.is_douyin = 0
      }
    },
    startTime() {
      const baseTime = this.baseInfo.song_issue
      if (!baseTime) return ''
      const first_period_day1 = (this.formData.auth_form1 === 2 && this.formData.first_period_day1) ?
          this.formData.first_period_day1 : 0
      return moment((new Date(baseTime)).getTime()  + (first_period_day1) * 3600 * 24 * 1000).format('yyyy-MM-DD HH:mm:ss')
    },
    issueTime(){
      const baseTime = this.baseInfo.song_issue
      if (!baseTime) return ''
      return baseTime
    }
  },

  watch: {
    value(val) {
      this.formData = val
    },
    // 'formData.first_period_day1'(){
    //   console.log('first_period_day1')
    //   this.formData.wyy_auth_start_time = this.formData.is_wangyi ? this.startTime() : ''
    //   this.formData.qs_auth_start_time = this.formData.is_qishui ? this.startTime() : ''
    // },
    // 'formData.auth_form1'(val){
    //   console.log('auth_form1')
    //   if (val === 1) {
    //     this.formData.is_wangyi = 0
    //     this.formData.is_qishui = 0
    //   } else {
    //     this.formData.wyy_auth_start_time = this.formData.is_wangyi ? this.startTime() : ''
    //     this.formData.qs_auth_start_time = this.formData.is_qishui ? this.startTime() : ''
    //   }
    // },
    // 'formData.is_wangyi'(val){
    //   console.log('is_wangyi')
    //   if (!val){
    //     this.formData.wyy_auth_start_time = ''
    //   } else if (!this.formData.wyy_auth_start_time) {
    //     if (this.formData.auth_form1 === 1){
    //       this.formData.wyy_auth_start_time = ''
    //     } else {
    //       this.formData.wyy_auth_start_time = this.startTime()
    //     }
    //   }
    // },
    // 'formData.is_qishui'(val){
    //   console.log('is_qishui')
    //   if (!val){
    //     this.formData.qs_auth_start_time = ''
    //   } else if (!this.formData.qs_auth_start_time) {
    //     if (this.formData.auth_form1 === 1){
    //       this.formData.qs_auth_start_time = ''
    //     } else {
    //       this.formData.qs_auth_start_time = this.startTime()
    //     }
    //   }
    // },
    // 'formData.is_douyin'(val) {
    //   console.log('is_douyin')
    //   if(!val) { // 反选
    //     this.formData.dy_auth_form = null
    //     this.materialInfo.id_file_7 = undefined
    //     this.formData.dy_auth_start_time = undefined
    //     this.formData.ks_use_dy_file = 0
    //   } else if (!this.formData.dy_auth_start_time) {
    //     this.formData.dy_auth_start_time = this.baseInfo.song_issue
    //
    //     if (this.formData.auth_form1 === 1){
    //       this.formData.wyy_auth_start_time = ''
    //       this.formData.qs_auth_start_time = ''
    //     } else {
    //       this.formData.wyy_auth_start_time = this.startTime()
    //       this.formData.qs_auth_start_time = this.startTime()
    //     }
    //   }
    // },
    // 'formData.is_kuaishou'(val) {
    //   if(!val) { // 反选
    //     this.formData.ks_auth_form = null
    //     this.materialInfo.id_file_16 = undefined
    //     this.formData.ks_auth_start_time = undefined
    //   } else if (!this.formData.ks_auth_start_time) {
    //     this.formData.ks_auth_start_time = this.baseInfo.song_issue
    //   }
    // }
  },

  computed: {
  }
}
</script>

<style scoped>
.platform{
  background-color: #f2f2f2;
  padding: 6px 10px;
  margin-bottom: 22px;
}
::v-deep .el-form-item__label{
  margin-left: 10px;
}
</style>
