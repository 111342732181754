<template>
  <div class="el-input el-input--medium el-input--suffix">
    <div class="el-input__inner " style="height: auto;line-height: 30px;padding: 2px 0;display: flex;flex-wrap: wrap;min-height: 34px">
      <el-tag
          type="info"
          size="small"
          :closable="!disabled"
          style="margin: 2px 0 2px 6px;"
          v-for="(item, index) in value"
          :key="index"
          disable-transitions
          @close="value.splice(index, 1)">
        {{ item }}
      </el-tag>
      <input :disabled="disabled" class="query" v-model="query" :placeholder="!value.length && !disabled ? placeholder : ''" @blur="queryBlur" @keydown.enter="queryBlur"/>
    </div>

  </div>
</template>

<script>
export default {
  name: "HiSelect",

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      query: ''
    }
  },

  methods: {
    queryBlur() {
      if (this.query === '') return
      this.value.push(this.query)
      this.query = ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/style/theme/index.css";
.query{
  border: 0;
  outline: none;
  margin-left: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1;
}
::v-deep .el-tag__close {
  margin-top: 3px;
}
</style>
