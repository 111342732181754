export default {
  id_contract_files: null,
  file_complete: undefined,
  remark: undefined,
  tape_right: undefined,
  lyrics_right: undefined,
  tune_right: undefined,
  auth_form1: 1,
  wyy_copyright_end_time1: null,
  first_period_day1: 1,
  other_platform: [],
  dy_auth_form: 0,
  ks_auth_form: 0,
  tape_right_date: '',
  tape_right_dateValue: [],
  tape_right_type: 0,
  performer_right_date: '',
  performer_right_dateValue: [],
  performer_right_type: 0,
  lyrics_right_date: '',
  lyrics_right_dateValue: [],
  lyrics_right_type: 0,
  tune_right_date: '',
  tune_right_dateValue: [],
  tune_right_type: 0,
  is_qishui: 0,
  is_wangyi: 0,
  is_kuaishou: 0,
  is_douyin: 0,
  ks_use_dy_file: 0,
  dy_part_file_url: [],
  ks_part_file_url: [],
  qs_file_url: [],
  wyy_file_url: [],
  dy_auth_start_time: '',
  ks_auth_start_time: '',
  qs_auth_start_time: '',
  wyy_auth_start_time: '',
  dy_auth_end_time: '',
  ks_auth_end_time: '',
  qs_auth_end_time: '',
  wyy_auth_end_time: '',

}
