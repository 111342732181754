<template>
  <div>
    <div class="stepShow">
      <steps
          :step="currentStep"
          :steps="[
          { text: '上传作品', icon: { active: 'release/01.png', default: 'release/01.png' } },
          { text: '入库审核', icon: { active: 'issue/04.png', default: 'issue/03.png' } },
          { text: '发行完成', icon: { active: 'release/05.png', default: 'release/06.png' } },
        ]"
      ></steps>
    </div>
    <div v-if="!id">
      <hi-draft-popup v-if="queryIssue" ref="draftPopup" @selectDraft="selectDraft"></hi-draft-popup>
    </div>
    <div v-else style="min-height: 15px">
      <div v-if="status === -2 || status === -3 || status === -4"
           style="height: 40px;padding-left: 40px;background-color: #fff4cd;border-radius: 4px;font-size: 14px;display: flex;margin: 15px 0;align-items: center">
        <img alt="" src="../../assets/issue-fail.png" style="width: 20px;height: 20px;margin-right: 6px;">
        <span style="color: #ff382b;margin-right: 10px">审核未通过</span>
        <span style="color: #AAAAAA">{{ album_audits }}</span>
      </div>
    </div>
    <div>
      <upload-album ref="album" v-model="album"></upload-album>
    </div>
    <div style="margin-top: 15px">
      <upload-songs ref="uploadSongs" v-model="songList"></upload-songs>
    </div>
    <div v-if="queryIssue" style="margin-top: 32px;display: flex;justify-content: center;gap: 15px">
      <d-button v-if="status === 0" style="border-radius: 3px;font-size: 14px;font-family: PingFang SC, PingFang SC-Regular;border: 1px solid #dedede;font-weight: 400;color: #666666;background: #ffffff;" type="gray"
                @click="handleSubmit(true)">
        保存草稿
      </d-button>
      <d-button style="border-radius: 3px" @click="handleSubmit(false)">提交审核</d-button>
    </div>
    <div v-else style="margin-top: 32px;display: flex;justify-content: center;gap: 15px">
      <d-button style="width: 140px;height: 44px;background: #ff004d;border-radius: 3px;" @click="$router.back()">返回
      </d-button>
    </div>
  </div>
</template>

<script>
import UploadSongs from "@/views/issue/components/UploadSongs"
import uploadAlbum from "@/views/issue/components/uploadAlbum"
import DButton from "@/components/DButton"
import {createAlbum} from "@/api"
import HiDraftPopup from "@/views/issue/components/hiDraftPopup";
import steps from "@/components/Steps";
// import moment from "moment/moment"

export default {
  name: "Issue",

  components: {
    HiDraftPopup,
    DButton,
    uploadAlbum,
    UploadSongs,
    steps
  },

  props: {
    id: String,
    ver: String,
    songId: String,
  },

  data() {
    return {
      submitLock: false,
      album: {
        cover_map_id: undefined,
        cover_md5: '',
        draft: 0,//是否为草稿0:直接提交1：草稿
        album_id: 0,//专辑id
        subject_no: '',//版权公司
        album_name: '',//专辑名称
        singer_info: [],//专辑歌手
        introduction: '',//专辑简介
        issued_time: undefined,//专辑发行时间
        brand_show_name: undefined,//专辑外显公司名称
        language: '0',//语言
        album_area: '1',//地区
        album_type: '10',//专辑类型
        album_version: undefined,//专辑版本
      },
      songList: [],
      currentStep: 1,
      status: 0,
      verNum: 1,
      album_audits: '',

      // 从专辑列表选择的专辑中的歌曲列表的长度，用于锁定歌曲，防止被删除
      songProtect: 0
    }
  },

  mounted() {
    if (this.id !== undefined && this.ver !== undefined) {
      this.selectDraft(this.id);
    }
  },

  methods: {
    handleSubmit(isDraft = false) {
      if (this.submitLock) return
      this.submitLock = true
      const draft = isDraft ? 1 : 0

      const form = {album: this.album, songList: this.songList, draft}
      if (form.album.album_id) form.album.album_id = parseInt(form.album.album_id)
      form.songList.forEach(e => {
        e.copyrightInfo.tape_right_date = e.copyrightInfo.tape_right_dateValue.join(' - ')
        e.copyrightInfo.performer_right_date = e.copyrightInfo.performer_right_dateValue.join(' - ')
        e.copyrightInfo.lyrics_right_date = e.copyrightInfo.lyrics_right_dateValue.join(' - ')
        e.copyrightInfo.tune_right_date = e.copyrightInfo.tune_right_dateValue.join(' - ')

        if(e.copyrightInfo.is_douyin === 0){
          e.copyrightInfo.dy_auth_start_time = undefined
          e.copyrightInfo.dy_auth_form = undefined
          e.materialInfo.id_file_7 = undefined
        }
        if(e.copyrightInfo.is_kuaishou === 0){
          e.copyrightInfo.ks_auth_start_time = undefined
          e.copyrightInfo.ks_auth_form = undefined
          e.materialInfo.id_file_16 = undefined
        }
        if(e.copyrightInfo.auth_form1 !== 2){
          e.copyrightInfo.first_period_day1 = undefined
        }

      })
      console.log(form)
      createAlbum(form).then(r => {
        console.log(r)
        this.submitLock = false
        if (r.code === 0) {
          this.$message.success('提交成功')
          if (isDraft) {
            this.$router.push('/draftList')
          } else {
            this.$router.push('/applyList')
          }
        }
      }).catch(e => {
        console.log(e)
        this.submitLock = false

      })
    },
    getAlbumDetails(id, isSongProtect = false) {
      this.$post('/api/issue/universal/album/show', {id: id}).then(r => {

        let {
          id: album_id,
          subject_no,
          album_name,
          version: album_version,
          singers,
          album_type,
          album_area,
          language,
          brand_show_name,
          issued_time,
          introduction,
          cover,
          applys,
          status,
          ver
        } = r.data
        console.log(applys,'applys');
        this.status = status
        this.verNum = ver;
        this.album_audits = r.data.album_audits
        let singer_info;
        if (singers !== null) {
          console.log(singers,'singers')
          singer_info = singers.map(e => {
            return {
              singer_id: e.singer.singer_id,
              singer_name: e.singer.singer_name,
              singer_pic: e.singer.singer_pic,
              page_q: e.singer.qq_music_homepage,
              page_dy: e.singer.douyin_homepage,
              page_wy: e.singer.wangyi_homepage,
              fans_q: e.singer.qq_music_fans,
              fans_kg: e.singer.kugou_fans,
              fans_dy: e.singer.douyin_fans,
              page_qs:e.singer.qishui_homepage
            }
          })
          this.$refs.album.singer_infoOptions = singer_info;
        } else {
          singer_info = []
        }
        let cover_map_id, cover_md5
        if (cover) {
          cover_map_id = cover.id
          cover_md5 = cover.md5
        }
        this.album = {
          album_id,
          subject_no,
          album_name,
          album_version,
          singer_info,
          album_type,
          album_area,
          language,
          brand_show_name,
          issued_time,
          introduction,
          cover_map_id,
          cover_md5
        }
        this.songList = applys.map(this.songShow)
        if (this.songId) {
          for (let i = 0; i < this.songList.length; i++) {
            if (this.songList[i].baseInfo.apply_id === parseInt(this.songId)) {
              this.$refs.uploadSongs.activeName = i.toString()
              setTimeout(() => {
                document.getElementById('song' + i).scrollIntoView()
              }, 0)
            }
          }
        }

        if(isSongProtect){
          this.songProtect = applys.length || 0
        }
      })
    },
    //歌曲回显
    songShow(e) {
      return {
        baseInfo: {
          apply_id: e.lyrics.apply_id,
          song_name: e.name,
          song_title: e.subtitle,
          singer_sources: !e.singers ? [] : e.singers.map(e => {
            return {
              singerName: e.singer.singer_name,
              singerPic: e.singer.singer_pic,
              tmeSingerId: e.singer.singer_id,
              page_q: e.singer.qq_music_homepage,
              page_dy: e.singer.douyin_homepage,
              page_wy: e.singer.wangyi_homepage,
              fans_q: e.singer.qq_music_fans,
              fans_kg: e.singer.kugou_fans,
              fans_dy: e.singer.douyin_fans,
              page_qs:e.singer.qishui_homepage
            }
          }),
          version: e.version,
          song_version: e.version_note,
          song_language: e.language,
          song_issue: e.issued_time,
          is_accom: e.is_accom,
          accom_lyric_sync: e.accom_lyric_sync,
          accom_version: e.accom_version_note,
          subject_no: e.subject_no
        },
        resourceInfo: {
          need_fans: e.need_fans.toString(),
        },
        copyrightInfo: {
          id_contract_files: e.contract_files_id || '',
          file_complete: e.file_complete,
          remark: e.remark,
          tape_right: e.tape_right,
          tape_right_type: e.tape_right_type,
          tape_right_date: e.tape_right_date,
          tape_right_dateValue: e.tape_right_date.split(' - ') || [],
          performer_right: e.performer_right,
          performer_right_type: e.performer_right_type,
          performer_right_date: e.performer_right_date,
          performer_right_dateValue: e.performer_right_date.split(' - ') || [],
          lyrics_right: e.lyrics_right,
          lyrics_right_type: e.lyrics_right_type,
          lyrics_right_date: e.lyrics_right_date,
          lyrics_right_dateValue: e.lyrics_right_date.split(' - ') || [],
          tune_right: e.tune_right,
          tune_right_type: e.tune_right_type,
          tune_right_date: e.tune_right_date,
          tune_right_dateValue: e.tune_right_date.split(' - ') || [],
          wyy_copyright_end_time1: e.tapes.wyy_copyright_end_time,
          first_period_day1: e.tapes.first_period_day || 1,
          other_platform: e.tapes.other_platform ? e.tapes.other_platform.split(',').map(e => parseInt(e)) : [],
          dy_auth_form: e.tapes.dy_auth_form,
          ks_auth_form: e.tapes.ks_auth_form,
          auth_form1: parseInt(e.tapes.auth_form) || 1,
          is_qishui: e.tapes.is_qishui,
          is_wangyi: e.tapes.is_wangyi,
          is_douyin: e.tapes.is_douyin,
          is_kuaishou: e.tapes.is_kuaishou,
          wyy_auth_start_time: e.tapes.wyy_auth_start_time,
          qs_auth_start_time: e.tapes.qs_auth_start_time,
          dy_auth_start_time: e.tapes.dy_auth_start_time,
          ks_auth_start_time: e.tapes.ks_auth_start_time,
          wyy_auth_end_time: e.tapes.wyy_auth_end_time,
          qs_auth_end_time: e.tapes.qs_auth_end_time,
          dy_auth_end_time: e.tapes.dy_auth_end_time,
          ks_auth_end_time: e.tapes.ks_auth_end_time,
          ks_use_dy_file: e.ks_use_dy_file,
          dy_part_file_url: e.dy_part_file_url,
          ks_part_file_url: e.ks_part_file_url,
          qs_file_url: e.qs_file_url,
          wyy_file_url: e.wyy_file_url
        },
        materialInfo: {
          lyricist: e.lyricist ? e.lyricist.split(',') : [],
          composer: e.composer ? e.composer.split(',') : [],
          arranger: e.arranger ? e.arranger.split(',') : [],
          producer: e.producer ? e.producer.split(',') : [],
          planer: e.planer ? e.planer.split(',') : [],
          id_file_11: e.audio_file_id,
          id_file_11_md5: e.audio?.md5,
          id_file_14: e.cover_source_file_id,
          id_file_14_md5: e.cover_source?.md5,
          id_file_12: e.accom_audio_file_id,
          id_file_12_md5: e.audio_accom?.md5,
          id_file_10: e.accom_no_file_id,
          id_file_10_md5: e.audio_accom_no?.md5,
          id_file_7: e.part_main_file_id === '0' ? '' : e.part_main_file_id,
          id_file_16: e.ks_part_files?.map(e => e.id).join(','),
          id_file_8: e.part_sub_file_id,
          id_file_15: e.audio_rail_id_name,
          id_file_3: e.lyric_file_id,
          lyric_head: e.lyric_head,
          lyric: e.lyric,
        },
        styleInfo: {
          tag_adapter: e.tag.tag_adapter ? e.tag.tag_adapter.split(',').map(e => parseInt(e)) : [],
          tag_feeling: e.tag.tag_feeling ? e.tag.tag_feeling.split(',').map(e => parseInt(e)) : [],
          tag_language: e.tag.tag_language ? e.tag.tag_language.split(',').map(e => parseInt(e)) : [],
          tag_role: e.tag.tag_role,
          tag_scene: e.tag.tag_scene ? e.tag.tag_scene.split(',').map(e => parseInt(e)) : [],
          tag_speed: e.tag.tag_speed,
          tag_strength: e.tag.tag_strength,
          tag_style: e.tag.tag_style ? e.tag.tag_style.split(',').map(e => parseInt(e)) : [],
          tag_theme: e.tag.tag_theme ? e.tag.tag_theme.split(',').map(e => parseInt(e)) : [],
          tag_vertical: e.tag.tag_vertical ? e.tag.tag_vertical.split(',').map(e => parseInt(e)) : []
        }
      }
    },
    selectDraft(id) {
      this.getAlbumDetails(id);
      this.$refs.album.newlyBuildShow = true;
      this.$refs.album.choiceShow = false;
    }
  },

  computed: {
    queryIssue() {
      return this.$route.name !== 'IssueQuery'
    }
  },

  watch: {
    id(val) {
      if (val === undefined) {
        this.album = {
          cover_map_id: undefined,
          draft: 0,//是否为草稿0:直接提交1：草稿
          album_id: 0,//专辑id
          subject_no: '',//版权公司
          album_name: '',//专辑名称
          singer_info: [],//专辑歌手
          introduction: '',//专辑简介
          issued_time: undefined,//专辑发行时间
          brand_show_name: undefined,//专辑外显公司名称
          language: '0',//语言
          album_area: '1',//地区
          album_type: '10',//专辑类型
          album_version: undefined,//专辑版本
        }
        this.songList = [];
        this.status = 0;
      }
    }
  }
}
</script>
<!--样式统一放三级页面-->
<style lang="scss" scoped>
@import '../../styles/works';
::v-deep *{
  font-family: PingFang SC, PingFang SC-Light,system-ui;
}

.stepShow {
  height: 117px;
  box-sizing: border-box;
  padding: 0 300px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.applyList {
  padding: 0;
  background-color: #f7f7f7;

}

::v-deep {
  .el-input__inner {
    border-radius: 3px;
    color: #555555;
    font-weight: 300;
  }

  .el-input__inner::placeholder {
    color: #999999;
  }

  .el-tabs {
    background-color: #fcfcfc;
  }

  .el-form-item__label:before {
    position: relative;
    left: -10px;
    margin-right: -7px !important;
  }

  h5, h4 {
    margin-top: 20px;
    font-size: 15px;
    line-height: 1;
    margin-bottom: 32px;
    margin-left: -7px;
  }

  .el-input__inner::placeholder {
    font-weight: normal;
  }

  .el-textarea__inner::placeholder {
    font-weight: normal;
    font-family: PingFang SC, PingFang SC-Light;
    color: #999999;
  }
}
</style>

