<template>
  <div style="padding: 20px 40px;background-color: white;border-radius: 4px">
    <div class="addTitle">添加歌曲</div>
    <el-alert
        title="提示：建议歌曲数量保持在15首以内，避免因文件太大，无法成功提交至生态！"
        type="warning"
        :closable="false"
        show-icon>
    </el-alert>
    <el-collapse v-model="activeName" accordion v-show="value.length > 0">
      <el-collapse-item v-for="(item, index) in value" :title="item.baseInfo.song_name" :name="index + ''" :key="index" :id="'song' + index">
        <template slot="title">
          <div style="display: flex;justify-content: space-between;width: 100%;">
            <div style="padding-left: 18px">{{ index + 1 }}<el-image style="height: 12px;width: 12px;margin: 0 7px -2px 18px;" :src="require('@/assets/issue-audio-icon.png')"></el-image> {{ item.baseInfo.song_name }}</div>
            <div>
              <el-button type="text" v-if="$parent.songProtect <= index && !queryIssue && [0,-1,-2].indexOf($parent.status) > -1 && $parent.verNum === 1 || item.baseInfo.apply_id === 0 " @click="handleDelete(index)" style="margin-right: 27px;color: #999999;font-size: 14px">删除</el-button>
              <div style="display: inline-block">
                <div style="display: flex;align-items: center;padding-right: 30px">
                  <span style="color: #ff004d;font-size: 14px">详情</span>
                  <el-image style="" class="red-arrow" :class="{rotate: activeName === index.toString()}" :src="require('../../../assets/up-issue.png')" ></el-image>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-tabs v-model="activeName1"  type="card">
          <el-tab-pane label="基本信息" name="1">
            <base-info-form :router-query="$parent.songProtect <= index && !queryIssue" v-model="value[index].baseInfo" @asyncAlbumInfo="asyncAlbumInfo(index)"></base-info-form>
          </el-tab-pane>
          <el-tab-pane label="资源位信息" name="2">
            <resource-info-form :query-issue="!($parent.songProtect <= index && !queryIssue)" v-model="value[index].resourceInfo" :singer-sources="value[index].baseInfo.singer_sources"></resource-info-form>
          </el-tab-pane>
          <el-tab-pane label="版权信息" name="3">
            <copyright-info-form :query-issue="!($parent.songProtect <= index && !queryIssue)" :baseInfo="value[index].baseInfo"  v-model="value[index].copyrightInfo"></copyright-info-form>
          </el-tab-pane>
          <el-tab-pane label="授权平台信息" name="6">
            <platform-information :readonly="!($parent.songProtect <= index && !queryIssue)" :baseInfo="value[index].baseInfo"  v-model="value[index].copyrightInfo" :materialInfo="value[index].materialInfo"></platform-information>
          </el-tab-pane>
          <el-tab-pane label="物料信息" name="4">
            <material-info-form :query-issue="!($parent.songProtect <= index && !queryIssue)" :baseInfo="value[index].baseInfo"  v-model="value[index].materialInfo"></material-info-form>
          </el-tab-pane>
          <el-tab-pane label="曲风标签" name="5">
            <style-info-form :query-issue="!($parent.songProtect <= index && !queryIssue)" v-model="value[index].styleInfo"></style-info-form>
          </el-tab-pane>
        </el-tabs>
        <div style="display: flex;align-items: center;padding-right: 30px;right: 0;bottom: 24px;cursor: pointer;flex-direction: row-reverse;background-color: #fcfcfc" @click="activeName = ''">
          <el-image class="red-arrow rotate" :src="require('../../../assets/up-issue.png')" ></el-image>
          <span style="color: #ff004d;font-size: 14px">收起</span>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div v-if="!queryIssue" class="addSong" @click="handleUpload()">
      <div class="default-text">
        <el-image :src="uploadImage" style="width: 16px;height: 16px;margin-right: 8px"></el-image>新建歌曲
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store"
import BaseInfoForm from "@/views/issue/components/baseInfoForm"
import baseInfo from "@/views/issue/components/baseInfoForm/baseInfo"
import ResourceInfoForm from "@/views/issue/components/resourceInfoForm"
import CopyrightInfoForm from "@/views/issue/components/copyrightInfoForm"
import copyrightInfo from "@/views/issue/components/copyrightInfoForm/copyrightInfo"
import StyleInfoForm from "@/views/issue/components/styleInfoForm"
import MaterialInfoForm from "@/views/issue/components/materialInfoForm"
import materialInfo from "@/views/issue/components/materialInfoForm/materialInfo"
import uploadImage from "@/assets/upload/upload.png"
import platformInformation from "./platformInformation";

export default {
  name: "UploadSongs",
  components: {
    BaseInfoForm,
    ResourceInfoForm,
    CopyrightInfoForm,
    MaterialInfoForm,
    StyleInfoForm,
    platformInformation
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    songId:String,
  },
  created() {
    if(this.$route.params.songId !== undefined){
      this.activeName = Number(this.$route.params.songId);
    }
  },
  computed:{
    queryIssue(){
      return this.$route.name === 'IssueQuery'
    },
  },
  data() {
    return {
      uploadImage,
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      activeSong: false,
      // value:[
      //   {
      //     baseInfo,
      //     resourceInfo: {},
      //     copyrightInfo,
      //     materialInfo,
      //     styleInfo: {}
      //   }
      // ],
      activeName1: '1',
      activeName: '',
    }
  },

  methods: {
    handleDelete(index){
      this.$confirm('<p>删除后不可撤回，请确认是否删除?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'big-button',
        cancelButtonClass: 'big-button',
        dangerouslyUseHTMLString: true,
        center: true
      }).then(() => {
        this.value.splice(index, 1)
      }).catch(() => {});
    },
    asyncAlbumInfo(index) {
      const album = this.$parent.album
      const baseInfo = this.value[index].baseInfo
      const singers = []

      const albumNameSplit = album.album_name.split(' (')

      if (albumNameSplit.length === 2) {
        baseInfo.song_name = albumNameSplit[0]
        baseInfo.song_version = albumNameSplit[1].split(')')[0]
        baseInfo.accom_version = baseInfo.song_version + '伴奏'
      } else {
        baseInfo.song_name = album.album_name
      }

      album.singer_info.forEach(e => {
        singers.push({
          "singerName": e.singer_name,
          "tmeSingerId": e.singer_id,
          "singerPic": e.singer_pic,
          page_q:e.page_q,
          page_dy:e.page_dy,
          page_wy:e.page_wy,
          fans_q:e.fans_q,
          fans_kg:e.fans_kg,
          fans_dy:e.fans_dy,
          page_qs:e.page_qs
        })
      })
      baseInfo.singer_sources = singers
      baseInfo.song_issue = album.issued_time
    },
    handleUpload(){
      const song = JSON.parse(JSON.stringify({
        baseInfo,
        resourceInfo: {
          need_fans: '0'
        },
        copyrightInfo,
        materialInfo,
        styleInfo: {}
      }))
      this.value.push(song)
      this.activeName = (this.value.length - 1).toString()
    },
  },

  watch:{
    value: {
      handler(val){
        this.$emit('input',val);
        if (this.activeName === '') {
          this.activeName = this.value[0].baseInfo.apply_id
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped lang="scss">
.default-text{
  font-size: 12px;
  color: #ff004d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.addSong{
  margin-top: 40px;
  height: 100px;
  width: 100%;
  border: #cecece 1px dashed;
  border-radius: 4px;
  background-color: #fcfcfc;
  display: flex;
  cursor: pointer;
}
.addSong:hover{
  border-color: #ff004d;
}
.addTitle {
  font-size: 16px;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  margin-bottom: 20px;
}
.upload-demo{
  width: 100%;
  .upload-bg{
    width: 140px;
    height: 100px;
    background: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 16px;
      height: 16px;
    }
    p{
      font-size: 12px;
      font-weight: 400;
      color: #ff004d;
      margin-bottom: 11px;
    }
  }
}

::v-deep .el-form-item__label{
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  font-weight: 300;
  color: #555555;
}
::v-deep {
  .el-collapse{
    border: 0;
    background-color: #f3f3f3;
    border-radius: 4px;
    .el-collapse-item__header{
      background-color: #f3f3f3;
      border-radius: 4px;
      height: 60px;
    }
  }
  .el-tabs__header{
    background-color: #f3f3f3;
    border-bottom: 0;
  }
  .el-tabs__nav{
    border: 0!important;
  }
  .el-tabs__item{
    border-color: #f3f3f3!important;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    &::before{
      content:'*';
      margin-right: 4px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #ff004d;
    }
  }
  .el-tabs__item.is-active{
    background-color: white;
    border-color: #f3f3f3;
    border-bottom: 0;
    border-left: 1px solid;
  }
  .el-collapse-item__arrow {
    display: none;
  }
  .el-collapse-item__content{
    padding-bottom: 0;
  }
  .el-collapse-item__wrap{
    border-bottom: 0;
  }
  .el-radio__input.is-checked+.el-radio__label{
    color: inherit;
  }
  .el-textarea__inner{
    border-radius: 3px;
  }
  .el-tabs__item+.is-top+.is-active{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .el-form-item__label{
    font-family: PingFang SC-Light;

  }
  .el-input__inner::placeholder{
    font-weight: normal;
  }
}

.red-arrow{
  width: 24px;
  height: 24px;
  margin-left: 4px;
  transition: all .3s;
  transform: rotate(180deg);
}
.rotate{
  transform: rotate(0deg);
}
</style>
